export enum Timezone {
  US_HAWAII = 'US/Hawaii',
  US_ALASKA = 'US/Alaska',
  US_PACIFIC = 'US/Pacific',
  US_MOUNTAIN = 'US/Mountain',
  US_CENTRAL = 'US/Central',
  US_EASTERN = 'US/Eastern',
  CANADA_ATLANTIC = 'Canada/Atlantic',
  MEXICO_BAJA_CALIFORNIA = 'Mexico/BajaCalifornia',
  MEXICO_CHIHUAHUA = 'Mexico/Chihuahua',
  AMERICA_CENTRAL_AMERICA = 'America/CentralAmerica',
  MEXICO_GENERAL = 'Mexico/General',
  UTC = 'UTC',
}

export const availableTimezones: Timezone[] = [
  Timezone.US_HAWAII,
  Timezone.US_ALASKA,
  Timezone.US_PACIFIC,
  Timezone.US_MOUNTAIN,
  Timezone.US_CENTRAL,
  Timezone.US_EASTERN,
  Timezone.CANADA_ATLANTIC,
  Timezone.MEXICO_BAJA_CALIFORNIA,
  Timezone.MEXICO_CHIHUAHUA,
  Timezone.AMERICA_CENTRAL_AMERICA,
  Timezone.MEXICO_GENERAL,
  Timezone.UTC,
];
