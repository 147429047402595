import { AuthRepository } from '@repository/edge/auth/authRepository';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { PasswordRepository } from '@repository/auth/password/passwordRepository';
import { type ResetPasswordAdminDto, type ResetPasswordDto } from '@datasource/auth/password/dto/resetPasswordDto';
import { LoginRepository } from '@repository/auth/login/loginRepository';
import { type AuthenticationResponse, type LoginDto } from '@datasource/auth/login/dto/login.dto';

interface AuthUseCaseProps {
  getLoggerTokenAsync: () => Promise<ApiResponse<string>>;
  resetPassword: (body: ResetPasswordDto, access_token: string) => Promise<ApiResponse<Record<string, string>>>;
  resetPasswordAdmin: (body: ResetPasswordAdminDto) => Promise<ApiResponse<Record<string, string>>>;

  login: (body: LoginDto) => Promise<ApiResponse<AuthenticationResponse>>;
}

export function AuthUseCase(): AuthUseCaseProps {
  const { getLoggerTokenAsync } = AuthRepository();
  const { resetPassword, resetPasswordAdmin } = PasswordRepository();
  const { loginUser } = LoginRepository();

  return {
    async getLoggerTokenAsync() {
      const { data, error } = await getLoggerTokenAsync();
      if (data === null) {
        return { error };
      }
      return { data };
    },

    async resetPassword(body: ResetPasswordDto, token: string): Promise<ApiResponse<Record<string, string>>> {
      const { data, error } = await resetPassword(body, token);
      if (data === null) {
        return { error };
      }
      return { data };
    },

    async resetPasswordAdmin(body: ResetPasswordAdminDto): Promise<ApiResponse<Record<string, string>>> {
      const { data, error } = await resetPasswordAdmin(body);
      if (data === null) {
        return { error };
      }
      return { data };
    },

    async login(body: LoginDto): Promise<ApiResponse<AuthenticationResponse>> {
      const { data, error } = await loginUser(body);
      if (data === null) {
        return { error };
      }
      return { data };
    },
  };
}
