import React, { type FC, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import SettingsTitleDivider from '@views/Settings/Components/SettingsTitleDivider.component';
import { type ActiveCallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import ActiveSessionsViewmodel from '@views/Settings/ActiveSessions/ActiveSessions.viewmodel';
import { Button } from '@components/ui/button';
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@components/ui/collapsible';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioGroup, RadioGroupItem } from '@/src-v2/Presentation/Common/Components/ui/radio-group';
import { IconUserOff, IconZoomIn } from '@tabler/icons-react';
import useTimeAgo from '@/src/Presentation/Common/hooks/useTimeAgo.hook';
import { RefreshCwIcon } from 'lucide-react';
import moment from 'moment-timezone';

const SessionsView: FC = () => {
  const { sessionsData, toggleResumePauseSession, removeAgentsBySessionId, fetchSessionInfo } =
    ActiveSessionsViewmodel();
  const [isOpened, openAgentsList] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { timeAgo, updateTimeAgoDate } = useTimeAgo();

  const handleRedirect = (callSessionId: string): void => {
    const path = `${location.pathname}/${callSessionId}`;
    navigate(path);
  };

  const handleDeleteAgents = async (callSessionId: string): Promise<void> => {
    await removeAgentsBySessionId(callSessionId);
  };

  function toggleOpenAgentList(): void {
    if (!isOpened) {
      openAgentsList(true);
    }
    if (isOpened) {
      openAgentsList(false);
    }
  }

  const handleUpdateSesionStatus = async (callSessionId: string, status: string): Promise<void> => {
    await toggleResumePauseSession(callSessionId, { status });
  };

  const handleSessionStatus = (callSessionId: string, status: string): void => {
    void handleUpdateSesionStatus(callSessionId, status);
  };

  const handleSdrStatus = (callSessionId: string, status: string): void => {
    void handleUpdateSdrStatus(callSessionId, status);
  };

  const handleUpdateSdrStatus = async (callSessionId: string, status: string): Promise<void> => {
    await toggleResumePauseSession(callSessionId, { sdrStatus: status });
  };

  const reload = async (): Promise<void> => {
    await fetchSessionInfo();
    updateTimeAgoDate(new Date());
  };

  const handleReload = (): void => {
    void reload();
  };

  useEffect(() => {
    updateTimeAgoDate(new Date());
  }, [sessionsData]);

  return (
    <>
      <SettingsTitleDivider title="Active Call Sessions" description="Watch and manage the active call sessions" />
      <div className="flex justify-end w-full px-4 mb-8 mt-4">
        <Button
          onClick={handleReload}
          className="flex items-center space-x-2 bg-transparent border-none text-blue-500 hover:text-blue-700"
        >
          {timeAgo !== undefined && <span>{timeAgo}</span>}
          <RefreshCwIcon className="h-5 w-5" />
        </Button>
      </div>
      <Table className="table-auto">
        <TableHeader>
          <TableCell>Session</TableCell>
          <TableCell>Organization</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>SDR</TableCell>
          <TableCell>Agents</TableCell>
          <TableCell>Session Status</TableCell>
          <TableCell>SDR Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableHeader>
        <TableBody>
          {sessionsData?.map((callSession: ActiveCallSession, index) => {
            const { callSessionId, organization, startAt, sdrName, sdrId, agents, status, sdrStatus } = callSession;
            return (
              <TableRow key={callSessionId}>
                <TableCell className={'max-w-1/2 max-[1200px]:break-all'} id={`callSessionId-${index}`}>
                  {callSessionId}
                </TableCell>
                <TableCell>{organization}</TableCell>
                <TableCell>{moment(startAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell className="max-w-1/2 max-[1200px]:break-all">
                  {sdrName}
                  <br />
                  <h3 className="text-app-gray-300">id: {sdrId}</h3>
                </TableCell>
                {agents !== null && agents.length <= 4 ? (
                  <TableCell>
                    {agents.map((el, index) => {
                      return (
                        <div key={index}>
                          <h3>{el.name}</h3>
                        </div>
                      );
                    })}
                  </TableCell>
                ) : agents !== null && agents.length > 4 ? (
                  <TableCell>
                    {agents.slice(0, 4).map((el, idx) => {
                      return (
                        <div key={idx}>
                          <h3>{el.name}</h3>
                        </div>
                      );
                    })}

                    <Collapsible>
                      <CollapsibleContent>
                        {agents.slice(4).map((el, idx) => {
                          return (
                            <div key={idx}>
                              <h3>{el.name}</h3>
                            </div>
                          );
                        })}
                      </CollapsibleContent>
                      <div className="flex flex-col items-center justify-between">
                        <CollapsibleTrigger>
                          <Button className="h-5 w-6 p-0 mr-8" type="button" onClick={toggleOpenAgentList}>
                            {isOpened ? <MinusIcon /> : <PlusIcon />}
                          </Button>
                        </CollapsibleTrigger>
                      </div>
                    </Collapsible>
                  </TableCell>
                ) : (
                  <TableCell>
                    <h3>
                      <b>
                        No agents assigned <br />
                        for this Session
                      </b>
                    </h3>
                  </TableCell>
                )}

                <TableCell>
                  <RadioGroup defaultValue={status}>
                    <div className="flex items-center space-x-2 text-xs">
                      <RadioGroupItem
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const target = e.target as HTMLInputElement;
                          handleSessionStatus(callSessionId, target.value);
                        }}
                        value="started"
                        id="r3"
                      />
                      <h3>Started</h3>
                    </div>
                    <div className="flex items-center space-x-2 text-xs">
                      <RadioGroupItem
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const target = e.target as HTMLInputElement;
                          handleSessionStatus(callSessionId, target.value);
                        }}
                        value="paused"
                        id="r4"
                      />
                      <h3>Paused</h3>
                    </div>
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  <RadioGroup defaultValue={sdrStatus}>
                    <div className="flex items-center space-x-2 text-xs">
                      <RadioGroupItem
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const target = e.target as HTMLInputElement;
                          handleSdrStatus(callSessionId, target.value);
                        }}
                        value="free"
                        id="r1"
                      />
                      <span>Free</span>
                    </div>
                    <div className="flex items-center space-x-2 text-xs">
                      <RadioGroupItem
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const target = e.target as HTMLButtonElement;
                          handleSdrStatus(callSessionId, target.value);
                        }}
                        value="busy"
                        id="r2"
                      />
                      <span>Busy</span>
                    </div>
                  </RadioGroup>
                </TableCell>

                <TableCell>
                  <IconZoomIn
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleRedirect(callSession.callSessionId);
                    }}
                  />
                  <IconUserOff
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      void handleDeleteAgents(callSession.callSessionId);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default SessionsView;
