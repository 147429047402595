import { type ApiResponse } from '@/src/types/ApiResponse';
import { type TokenObject, type TokenRefreshResponse } from '@datasource/edge/auth/dto/refreshToken.dto';
import { AuthRepository } from '@repository/edge/auth/authRepository';
import useAuthApi, { type LogoutResponse } from '@/src/api/Auth/AuthAPI';
import { type AuthenticationResponse } from '@/src-v2/Data/DataSource/auth/login/dto/login.dto';

interface LayoutSessionTokensProps {
  edgeLogout: (token: string) => Promise<ApiResponse<string>>;
  edgeRefreshToken: (token: TokenObject) => Promise<ApiResponse<TokenRefreshResponse>>;
  authLogout: () => Promise<ApiResponse<any>>;
  authRefreshToken: () => Promise<ApiResponse<AuthenticationResponse>>;
}
export default function LayoutSessionTokensUseCase(): LayoutSessionTokensProps {
  const { edgeLogout, edgeRefreshToken } = AuthRepository();
  const { logout, refresh } = useAuthApi();
  return {
    async edgeLogout(token): Promise<ApiResponse<string>> {
      const { data, success, error } = await edgeLogout(token);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async edgeRefreshToken(token: TokenObject): Promise<ApiResponse<TokenRefreshResponse>> {
      const { data, success, error } = await edgeRefreshToken(token);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async authLogout(): Promise<ApiResponse<LogoutResponse>> {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data, success, error }: ApiResponse<LogoutResponse> = await logout();
      if (data === null || data === undefined) {
        return { error, success };
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { data, success: true };
    },

    async authRefreshToken(): Promise<ApiResponse<AuthenticationResponse>> {
      const { data, success, error } = await refresh();
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
  };
}
