import React, {Component} from 'react';
import LoginLeftColumn from "../Login/LoginLeftColumn";
import {
	LoginCell,
	LoginColumn,
	LoginGrid,
} from "../Login/LoginElements";
import styled from "styled-components";
import { colors } from "../../core/theme";
import { IconAlertTriangle } from '@tabler/icons-react';

const Title = styled.h3`
  font-size: 3rem;
  font-weight: 600;
  color: ${colors.secondDarkGray};
  margin: 1rem 0;
`;

const Text = styled.p`
  font-size: 2rem;
  font-weight: 400;
  color: ${colors.darkGray};
  margin: 1rem 0;
`;

class Maintenance extends Component {
	render() {
		return (
			<div>
				<LoginGrid columns={["35%", "1fr"]}>
					<LoginLeftColumn message={"Tendril Connect"} />
					<LoginCell backgroundColor={"#EFEFEF"} color="black">
						<LoginColumn
							alignItems="center"
							justifyContent="center"
							gap="2rem"
							padding="3em 0"
						>
							<Title>Page under maintenance</Title>

							<IconAlertTriangle style={{ width: '5rem', height:'5rem', color: `${colors.darkGray}`}} />

							<Text>Thank you for your understanding!</Text>

						</LoginColumn>
					</LoginCell>
				</LoginGrid>

			</div>
		);
	}
}

export default Maintenance;
