import React, { type FC, useEffect } from 'react';

interface CounterProps {
  remainingTTL: number;
  setRemainingTTL: (ttl: (prevCount: number) => number) => void;
}

const Counter: FC<CounterProps> = ({ remainingTTL, setRemainingTTL }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTTL((prevCount: number) => prevCount - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const seconds = remainingTTL % 60;
  const minutes = Math.floor(remainingTTL / 60);

  return (
    <div className="text-xl font-semibold text-[#a6a8aa] my-2">
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </div>
  );
};

export default Counter;
