import React from "react";
import {
  LoginCell,
  LoginColumn,
  LoginWelcomeMessage,
  LoginAnchor,
} from "./LoginElements";
import TendrilIcon from "../../../public/assets/icons/tendril_icon.svg";

type Props = {
  message?: string;
};

const LoginLeftColumn = (props: Props): JSX.Element => {
  return (
    <LoginCell gradient={["#56BEED", "#0098D8 35%"]} color="white">
      <LoginColumn
        alignItems="flex-start"
        justifyContent="center"
        gap="2rem"
        padding="3em"
      >
        <img src={TendrilIcon} alt="Tendril Logo" width="100" />
        <LoginWelcomeMessage>
          {props.message ? props.message : "Welcome back to Tendril Connect"}
        </LoginWelcomeMessage>
        <LoginAnchor href="https://www.tendril.us/privacy-policy">Terms of use and Privacy Policy</LoginAnchor>
      </LoginColumn>
    </LoginCell>
  );
};

export default LoginLeftColumn;
