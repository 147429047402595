import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/shared/Layout";
import { Flex } from "../../core/ui";
import { colors } from "../../core/theme";
import axios from "axios";
import routes from "../../constants/routes";
import styled from "styled-components";
import { PaginatedProps, SearchProps } from "../../core/ui/UITable";
import Modal from "../../components/shared/Modal";
import { isTendrilAdmin } from "../../core/utils/userTypeValidator";
import tabTitle from "../../utils/updateTitle";
import { featuresStore } from "../../components/app/FeaturesProvider";

import UITable from "../../core/ui/UITable";
import UICard from "../../core/ui/UICard";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../core/ui/UILoader";
import { showUIToast } from "../../core/ui/UIToast";
import { getSdrIdsByNameOrOrganization } from "../../api/Dashboard/dashboardRequestsAsync";
import getSdrsAsync from "../../api/Users/getAllSdrsAsync";
import { UserProviderContext } from "../../components/app/UserProvider";
import getAllOrganizationsAsync from "../../api/Organizations/getAllOrganizationsAsync";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { customOutreachLogo } from "../../core/ui/icons/CustomOutreachLogo";
import { customHubspotLogo } from "../../core/ui/icons/CustomHubspotLogo";
import { customTendrilLogo } from "../../core/ui/icons/TendrilLogo";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

const List = styled.ul`
  color: ${colors.darkGray};
  list-style-type: disc;
`;
const DescriptionContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-left: 2rem;
`;
const Bold = styled.span`
  font-weight: 600;
`;

const icons = {
  OUTREACH: (
    <FA
      icon={customOutreachLogo}
      className="text-app-integrations-outreach h-5 w-5"
    />
  ),
  HUBSPOT: (
    <FA
      icon={customHubspotLogo}
      className="text-app-integrations-hubspot h-5 w-5"
    />
  ),
  CUSTOM_HUBSPOT: (
    <FA
      icon={customHubspotLogo}
      className="text-app-integrations-hubspot h-5 w-5"
    />
  ),
  CSV: <FA icon={customTendrilLogo} className="text-app-blue-light h-7 w-7" />,
  FOURMATIV: <FA icon={faFileCsv} className="h-5 w-5" />,
};

const Dashboard = (): JSX.Element => {
  const {userSession, handleRoutesLogout, intervals, setIntervals} = useOutletContext() as any;
  const [callSessions, setCallSessions] = useState([] as any[]);
  const [callSessionId, setCallSessionId] = useState("");
  const [extraData, setExtraData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const { featuresState } = useContext(featuresStore);
  const {
    currentUser: { type, userId, userName },
  } = useContext(UserProviderContext);
  const { fourMativ } = featuresState;
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    callsessions: boolean;
  }>({ sequences: true, callsessions: true });
  tabTitle("Tendril Connect | Dashboard");

  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);

  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );

  const [modal, setModal] = useState<boolean>(false);
  const [idDownload, setIdDownload] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setSearchData({
      placeHolder: "Search by sdr, contact list name or organization",
      onSearch: handleSearch,
    });

    fetchData(userSession.userId);
  }, [pageNumber, search]);

  const fetchData = async (sdrId) => {
    const dashboardURL = `${routes.get.dashboard.getDashboardNest}/${sdrId}`;
    const encodeSearch = encodeURIComponent(search);

    const { organizationIds, sdrIds, error } =
      await getSdrIdsByNameOrOrganization(encodeSearch);

    if (error) {
      showUIToast({
        type: "error",
        text: "Error getting dashboard, try again later.",
      });
    }

    const {
      data: { data, ...rest },
    } = await axios.get(dashboardURL, {
      params: {
        page: pageNumber,
        pageLimit: 30,
        searchQuery: search,
        sdrIds: sdrIds,
        sdrOrgIds: organizationIds,
      },
    });

    setPaginatedData({
      ...rest,
      pageSize: 30,
      onPrevPage: handlePrev,
      onNextPage: handleNext,
    });

    await buildSessions(data);

    updateLoadingStatus({ ...loadingStatus, callsessions: false });
  };

  const handlePrev = async () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = async () => {
    setPageNumber((last) => last + 1);
  };

  const buildSessions = async (sessionData) => {
    const { error, organizations } = await getAllOrganizationsAsync();

    if (error || !organizations) {
      showUIToast({
        type: "error",
        text: "Failed to get organizations",
      });
    }

    const organizationsData = organizations ? organizations : [];

    const userIds: string[] = Array.from(
      new Set(sessionData.map((session) => session.sdrId))
    );

    const { allSdrs } = await getSdrsAsync(
      userSession.userId,
      userIds,
      null
    );

    sessionData.forEach((item) => {
      const organizationData = organizationsData.filter(function (org) {
        return org.id === item.organizationId;
      });

      if (allSdrs) {
        const sdrData = allSdrs?.filter(function (sdr) {
          return sdr.id === item.sdrId;
        });

        if (sdrData) {
          item.sdrName = sdrData[0] ? sdrData[0].name : "Not SDR";
        }
      } else if (type.some(({ type }) => type === "sdr")) {
        item.sdrName = userName;
      }

      item.organizationName = organizationData[0]
        ? organizationData[0].name
        : "Not Organization";
    });

    setCallSessions(sessionData);
  };

  const handleRenderFunction = ({ type }) => {
    return <div className="text-center">{icons[type]}</div>;
  };

  const headers = [
    { text: "Id", width: "1" },
    { text: "Contact list name ", width: "2" },
    { text: "User name ", width: "2" },
    { text: "List Type", width: "1" },
    { text: "Start at", width: "2" },
    { text: "Contacts", width: "1" },
    { text: "Organization", width: "2" },
    { text: "Download report", width: "1" },
  ];

  const tableBodies = [
    `shortId`,
    `listNames`,
    `sdrName`,
    {
      id: "type-id",
      customRender: true,
      renderFunc: handleRenderFunction,
    },
    `startAt.$date`,
    `totalContacts`,
    `organizationName`,
    {
      double: true,
      icon1: faFileCsv,
    },
  ];

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handleSummaryData = (callSessionId) => {
    navigate("/summary/" + callSessionId);
  };

  const downloadReport = async (e, id, type) => {
    if (id) {
      try {
        // Get with axios is necessary because sometimes call session doesn't
        // have metrics and on this way we can show feedback.
        const reportURL = `${routes.get.callSession.getReportNest}/${id}`;

        const createBlob = (data) => {
          const objURL = (window as any).URL.createObjectURL(new Blob([data]));
          const tempLink = document.createElement("a");
          tempLink.href = objURL;
          tempLink.setAttribute("download", `report-${id}.csv`);
          document.body.appendChild(tempLink);
          tempLink.click();

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(objURL);
          }, 200);
        };

        if (type === "raw") {
          const params = { type: "raw" };
          const callSession = callSessions.find(
            (callSession) => callSession.id === id
          );
          params["organizationId"] = callSession.organizationId;

          const { data }  = await axios.get(reportURL, { params });
          createBlob(data);
          setModal(false);
        }

        if (type === "compact") {
          const params = { type: "compact" };
          const { data } = await axios.get(reportURL, { params });
          createBlob(data);
          setModal(false);
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {

          if(e.request.status === 404){
           return showUIToast({
              type: "error",
              text: "Seems like your call session did not contain any call.",
            });
          }else{
            return showUIToast({
              type: "error",
              text: e.response.data.error,
            });
          }
        }
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
      }
    }
  };

  const getId = (e) => {
    if (e.id) {
      setIdDownload(e.id);
      setModal(true);
    }
  };

  return (
    <Layout
      sidebar
      handleLogout={handleRoutesLogout}
      user={userSession.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
        height="720px"
      >
        {loadingStatus.callsessions ? (
          <Loader message={"Loading call sessions, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={callSessions}
            visible={false}
            extraData={extraData}
            paginatedData={paginatedData}
            searchData={searchData}
            selectionHandler={getId}
            summaryHandler={handleSummaryData}
            setItemId={setCallSessionId}
          />
        )}
      </UICard>
      <Modal
        state={modal}
        setState={setModal}
        title={"Download report"}
        showHeader={true}
        showOverlay={true}
        modalPosition={"center"}
        padding={"20px"}
        showCloseBtn={true}
      >
        <>
          <p>Choose an export type</p>

          <DescriptionContainer>
            <List>
              <li>
                {" "}
                <Bold>Raw:</Bold> Includes all the stages of each call.
              </li>
              {!fourMativ && (
                <li>
                  {" "}
                  <Bold>Compact:</Bold> Only has the last status per call.
                </li>
              )}
            </List>
          </DescriptionContainer>

          <Flex flexDirection="row" justifyContent="space-between">
            <button onClick={(e) => downloadReport(e, idDownload, "raw")}>
              {" "}
              Raw{" "}
            </button>
            {!fourMativ && (
              <button onClick={(e) => downloadReport(e, idDownload, "compact")}>
                {" "}
                Compact{" "}
              </button>
            )}
          </Flex>
        </>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
