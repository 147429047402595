import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type DashboardSearchResponse } from '@datasource/edge/dashboard/entities/dashboardSearchResponse';

async function searchDashboardDataAsync(searchQuery: string): Promise<ApiResponse<DashboardSearchResponse>> {
  try {
    const dashboardInformation: ApiResponse<DashboardSearchResponse> = await axios.get(
      `${routes.get.dashboard.getSdrIdsByNameOrOrganizationNest}`,
      { params: { name: searchQuery } },
    );

    return { data: dashboardInformation.data, success: true };
  } catch {
    return { error: { message: 'Could not retrieve the requested params' }, success: false };
  }
}

export default searchDashboardDataAsync;
