import routes from "../../constants/routes";
import axios, { AxiosError } from "axios";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

export type TwilioCallResponse = {
  message: string;
};

export type CallData = {
  callId: string;
  sdrId: string;
  organizationId: string;
  callSessionId: string;
  agentId: string;
};

type ResponseProps = {
  twilioCallResponse?: TwilioCallResponse;
  error?: { message: string; status: number };
};

const twilioTransferCall = async (
  callData: CallData
): Promise<ResponseProps> => {
  try {
    const twilioCall = await axios.post<TwilioCallResponse>(
      `${routes.post.twilio.sdrConferenceNest}/call/${callData.callId}`,
      callData
    );
    return { twilioCallResponse: twilioCall.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logAxiosErrorWithSentry({
        payload: error,
        description: error.message,
      });

      const errorCode = error?.response?.data?.error?.cause?.code || null;
  
      return {
        error: {
          message: errorCode === 20404 ? 'Transfer failed due to participant leaving the conference.' :error.message,
          status: error.response?.status ?? -1,
        },
      };
    }

    return {
      error: {
        message: "There was an error while transferring the call",
        status: -1,
      },
    };
  }
};


export default twilioTransferCall;
