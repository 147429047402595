import React, { type FC, type ReactNode, useEffect, useState } from 'react';
import IdleTimerComponent from '@views/Layout/Components/IdleTimer.component';
import type IdleTimer from 'react-idle-timer';
import TimeoutAlertComponent from '@views/Layout/Components/TimeoutAlert.component';
import UIContainerComponent from '@views/Layout/Components/UIContainer.component';
import SidebarComponent from '@views/Layout/Components/Sidebar.component';
import NavbarComponent from '@views/Layout/Components/Navbar.component';
import { Button } from '@components/ui/button';
import { IconMenu2, IconX } from '@tabler/icons-react';
import useScreenSize from '@/src/core/hooks/useScreenSize';
import jwt_decode from 'jwt-decode';
import { type DecodedToken } from '@datasource/edge/auth/dto/refreshToken.dto';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@/src/constants/routesConnect';
import { LayoutViewModel } from '@views/Layout/Layout.viewmodel';

interface LayoutProps {
  sidebar?: boolean;
  userName?: string;
  children?: ReactNode;
}
const LayoutView: FC<LayoutProps> = ({ children }) => {
  const [showModalTimer, setShowModalTimer] = useState<boolean>(false);
  const { logoutUser, isAgent } = LayoutViewModel();
  const [isOpened, openSidebar] = useState(true);
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  const start: (ref: IdleTimer) => IdleTimer = (ref) => {
    return ref;
  };

  const toggleSidebar = (): void => {
    isOpened ? openSidebar(false) : openSidebar(true);
  };

  useEffect(() => {
    openSidebar(screenSize.width > 1400);
  }, [screenSize.width]);

  useEffect(() => {
    const token = window.localStorage.getItem('target');
    if (token !== null) {
      const decoded: DecodedToken = jwt_decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        console.log('Session closed by expired token');
        logoutUser();
        navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
        window.location.reload();
      }
    }
  }, []);

  return (
    <>
      <main className="z-8 min-h-screen grid grid-rows-[100px_1fr] text-primary">
        <NavbarComponent>
          {!isAgent && (
            <Button
              id="sidebarMenuButton"
              asChild
              size="icon"
              className="border border-primary h-12 w-12 p-3 rounded-lg hover:bg-white bg-white transition duration-5200 ease-in-out cursor-pointer"
              onClick={toggleSidebar}
            >
              {isOpened ? (
                <IconMenu2 stroke="2.5" color="hsl(var(--primary))" />
              ) : (
                <IconX stroke="2.5" color="hsl(var(--primary))" />
              )}
            </Button>
          )}
        </NavbarComponent>
        <div className="flex flex-row">
          {!isAgent ? <SidebarComponent opened={isOpened} /> : null}
          <UIContainerComponent>{children}</UIContainerComponent>
        </div>
      </main>
      <IdleTimerComponent pageRef={start} timedOutHandler={setShowModalTimer} />
      {showModalTimer && <TimeoutAlertComponent />}
    </>
  );
};

export default LayoutView;
