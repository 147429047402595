import { UsersUseCase } from '@/src-v2/Domain/UseCase/users/UsersUseCase';
import type React from 'react';
import { type ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { type SafeUserDto } from '@/src-v2/Data/DataSource/edge/users/dto/safeUser.dto';
import { type Timezone } from '@/src-v2/Domain/enums/Users/timezone';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { toast } from '@components/ui/use-toast';

interface ViewState {
  isEditing: boolean;
  hasImage: boolean;
  image: string;
  userName: string;
  email: string;
  currentTimezone: Timezone;
}
interface AccountViewModelProps {
  toggleEditView: () => void;
  cancelEdit: () => void;
  saveChanges: () => void;
  chooseFile: (e: ChangeEvent<HTMLInputElement>) => void;
  viewState: ViewState;
  removeImage: () => void;
  handleUserNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUserTimezoneChange: (e: string) => void;
}

const AccountViewModel = (): AccountViewModelProps => {
  const { getUserAsync, uploadProfilePicture, updateUserData } = UsersUseCase();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { currentUser, setCurrentUser } = useContext(UserProviderContext);
  const [file, setFile] = useState<File>();
  const [user, setUser] = useState<SafeUserDto>({
    email: '',
    id: '',
    name: '',
    organizationId: '',
    profilePicture: null,
    sdrId: null,
    timezone: '' as Timezone,
    userType: [],
  });
  const [viewState, setViewState] = useState<ViewState>({
    isEditing: false,
    hasImage: false,
    image: '',
    userName: '',
    email: '',
    currentTimezone: currentUser.timezone as Timezone,
  });
  const originalUser: React.MutableRefObject<SafeUserDto | undefined> = useRef(user);
  const defaultImageUri = 'tondril-dude.svg';

  function fetchUserInfo(): void {
    void getUserAsync(currentUser.userId).then((response) => {
      if (response.data !== undefined && typeof response.data.profilePicture === 'string') {
        setUser(response.data);
        setViewState({
          ...viewState,
          userName: response.data.name,
          email: response.data.email,
          hasImage: !response.data.profilePicture?.includes(defaultImageUri),
        });
        originalUser.current = response.data;
        return response.data;
      }
    });
  }

  function chooseFile(e: ChangeEvent<HTMLInputElement>): void {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      setFile(file);
      const imageUrl = URL.createObjectURL(file);
      setViewState({ ...viewState, image: imageUrl });
    }
  }

  function removeImageData(): void {
    user.profilePicture = defaultImageUri;
    setViewState({ ...viewState, image: defaultImageUri });
    setUser({ ...user, profilePicture: defaultImageUri });
    void updateUserData(user).then(() => {
      toast({
        title: 'Success!',
        description: 'Your profile picture was successfully removed',
        variant: 'default',
      });
    });
    void getUserAsync(user.id).then((res) => {
      const { data } = res;
      if (data?.profilePicture !== undefined && data?.profilePicture !== null) {
        setViewState({
          ...viewState,
          image: data.profilePicture,
          isEditing: false,
        });
      }
    });
  }

  function handleUserNameChange(e: ChangeEvent<HTMLInputElement>): void {
    const newName = e.target.value;
    setViewState({ ...viewState, userName: newName });
    setUser({ ...user, name: newName });
  }

  function handleUserTimezoneChange(e: string): void {
    const newTimezone = e as Timezone;
    setViewState({ ...viewState, currentTimezone: newTimezone });
    setUser({ ...user, timezone: newTimezone });
  }

  function saveChanges(): void {
    if (file !== undefined) {
      void uploadProfilePicture(currentUser.userId, { file }).then((response) => {
        setViewState({ ...viewState, hasImage: true });
        if (response.success !== false) {
          toast({
            title: 'Success!',
            description: 'Your profile picture was successfully uploaded',
            variant: 'default',
          });
        } else {
          toast({
            title: 'Error!',
            description: 'Your picture could not be uploaded',
            variant: 'destructive',
          });
        }
        setViewState({ ...viewState, isEditing: false });
      });
    }
    if (user.name !== originalUser.current?.name) {
      void updateUserData({ ...user, name: user.name }).then(() => {
        toast({
          title: 'Success!',
          description: 'Your profile data was successfully updated',
          variant: 'default',
        });
      });
    }
    if (user.timezone !== originalUser.current?.timezone) {
      console.log(`Changed timezone ${user.timezone} => ${originalUser.current?.timezone as string}`);
      void updateUserData({ ...user, timezone: user.timezone }).then(() => {
        toast({
          title: 'Success!',
          description: 'Your profile data was successfully updated',
          variant: 'default',
        });
      });
    }
    setViewState({ ...viewState, isEditing: false });
    setCurrentUser({ ...currentUser, userName: viewState.userName, timezone: viewState.currentTimezone });
  }

  function toggleEditView(): void {
    setViewState({ ...viewState, isEditing: true });
  }

  function cancelEdit(): void {
    setViewState({ ...viewState, isEditing: false, image: user.profilePicture as string, userName: user.name });
  }

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return {
    toggleEditView,
    cancelEdit,
    chooseFile,
    saveChanges,
    removeImage: removeImageData,
    handleUserNameChange,
    handleUserTimezoneChange,
    viewState,
  };
};
export default AccountViewModel;
