import DashboardUseCase from '@usecase/dashboard/DashboardUseCase';
import type React from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import {
  DashboardSession,
  type DashboardData,
  type PaginatedDashboardResponse,
} from '@datasource/contacts-nest/dashboard/entities/dashboardResponse';
import { type SdrResponse } from '@datasource/edge/users/entities/user.entity';
import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';
import { toast } from '@components/ui/use-toast';
import { type Timezone as TimezoneV2 } from '@domain/enums/Users/timezone';
import { type Timezone } from '@/src/types/TimezoneTypes';
import { PrivateRoutes } from '@/src/constants/routesConnect';

interface DashboardViewmodelProps {
  dashboardData: PaginatedDashboardResponse;
  fetchData: (page: number, queryValue?: string) => Promise<void>;
  generateReport: (callSessionId: string, type: string) => void;
  showSessionDetails: (callSessionId: string) => void;
  renderSummary: (callSessionId: string) => void;
  paginationData: { pageNumber: number; pageSize: number; totalPages: number; totalCount: number };
  timezone: Timezone | TimezoneV2;
  sessionId: React.MutableRefObject<string | undefined>;
}

export function DashboardViewmodel(): DashboardViewmodelProps {
  const [dashboardData, setDashboardData] = useState<PaginatedDashboardResponse>({
    data: [],
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
  });
  const [paginationData, setPagination] = useState({ pageNumber: 1, pageSize: 30, totalPages: 0, totalCount: 0 });
  const sessionId = useRef<string>();
  const navigate = useNavigate();
  const {
    userSession: { userId, timezone },
    userTypeAdmin,
  } = useOutletContext<OutletContextProps>();
  const { getDashboardData, searchInfoByName, getAllActiveSDRs, listAllOrganizations, getReportData } =
    DashboardUseCase();

  async function fetchData(page: number, queryValue?: string): Promise<void> {
    let encodeSearch;
    if (queryValue !== undefined) {
      encodeSearch = encodeURIComponent(queryValue);
    } else {
      encodeSearch = '';
    }

    const queriedData = await searchInfoByName(encodeSearch);

    const dashboardResponse = await getDashboardData(userId, {
      page,
      userTypeAdmin,
      pageLimit: 30,
      searchQuery: encodeSearch,
      sdrIds: queriedData.data?.sdrIds,
      organizationIds: queriedData.data?.organizationIds,
    });

    if (dashboardResponse.data !== undefined) {
      const builtDashboard = await buildDashboardSessions(dashboardResponse.data.data);

      setDashboardData({ ...dashboardData, data: builtDashboard, totalCount: builtDashboard.length });

      setPagination({
        pageNumber: dashboardResponse.data.pageNumber,
        pageSize: 30,
        totalPages: dashboardResponse.data.totalPages,
        totalCount: dashboardResponse.data.totalCount,
      });
    }
  }

  async function buildDashboardSessions(sessionsData: DashboardData[]): Promise<DashboardSession[]> {
    const organizations = await listAllOrganizations();
    const userIds = sessionsData.map((session) => session.sdrId);
    const sdrList = await getAllActiveSDRs(userId, userIds, '');
    let sdrData: SdrResponse;
    let orgData: ActiveOrganizations;

    return sessionsData.map((session) => {
      if (organizations.data !== undefined) {
        const sessionOrg = organizations.data.filter((organization) => {
          orgData = { id: organization.id, name: '' };
          return organization.id === session.organizationId;
        });

        orgData.name = sessionOrg[0] !== undefined ? sessionOrg[0].name : 'Not available';
      }

      if (sdrList.data !== undefined) {
        const sessionSdr = sdrList.data.filter((sdr) => {
          sdrData = { id: sdr.id, name: '' };
          return sdr.id === session.sdrId;
        });

        sdrData.name = sessionSdr[0] !== undefined ? sessionSdr[0].name : 'Not available';
      }
      if (sdrList.success === false || organizations.success === false) {
        toast({
          title: 'Error!',
          variant: 'destructive',
          description: 'Could not get complete data',
        });
      }

      return new DashboardSession(sdrData, orgData, session);
    });
  }

  function generateReport(callSessionId: string, type: string): void {
    void getReportData(callSessionId, type).then((reportResponse) => {
      if (reportResponse.data !== undefined) {
        const objectURL = window.URL.createObjectURL(new Blob([reportResponse.data]));
        const downloadAnchor = document.createElement('a');

        downloadAnchor.href = objectURL;
        downloadAnchor.setAttribute('download', `report-${callSessionId}.csv`);

        document.body.appendChild(downloadAnchor);

        downloadAnchor.click();

        toast({
          title: 'Success!',
          variant: 'default',
          description: 'The requested report was correctly generated.',
        });
      } else {
        toast({
          title: 'Error!',
          variant: 'destructive',
          description: 'The requested session does not contain any call',
        });
      }
    });
  }

  function showSessionDetails(callSessionId: string): void {
    sessionId.current = callSessionId;
    navigate(`/${PrivateRoutes.DASHBOARD_DETAILS}/${callSessionId}`, { replace: true });
  }

  const renderSummary = (callSessionId: string): void => {
    navigate('/summary/' + callSessionId);
  };

  return {
    fetchData,
    generateReport,
    showSessionDetails,
    renderSummary,
    paginationData,
    timezone,
    sessionId,
    dashboardData,
  };
}
