export const PublicRoutes = {
    LOGIN: 'login',
    PASSWORD_RECOVER: 'password/recover',
    PASSWORD_CHANGE: 'password/change',
    TEST: 'test',
};

export enum PrivateRoutes  {
    HOME = 'home',
    DIALER = 'dialer',
    CONTACTS = 'contacts',
    VOICE = 'voice',
    SCRIPT = 'script',
    SUMMARY = 'summary',
    MANAGEMENT = 'management',
    DASHBOARD = 'dashboard',
    DASHBOARD_V2 = 'dashboard-v2',
    DASHBOARD_DETAILS = 'dashboard-details',
    SETTINGS = 'settings',

    SETTINGS_ACCOUNT_V2 = "account-v2",
    SETTINGS_PHONE = "phone",
    SETTINGS_ACTIVE_SESSIONS = "active-sessions",

    SETTINGS_OUTREACH = "integrations/outreach/accounts",
    SETTINGS_OUTREACH_MAPPINGS = "integrations/outreach/mappings",

    SETTINGS_HUBSPOT = "integrations/hubspot/accounts",
    SETTINGS_HUBSPOT_MAPPINGS = "integrations/hubspot/mappings",

    SETTINGS_HUBSPOT_CUSTOM = "integrations/hubspot-custom/accounts",
    SETTINGS_HUBSPOT_CUSTOM_MAPPINGS = "integrations/hubspot-custom/mappings",
}
