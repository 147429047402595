import { ContactDialingStatus } from "./Contact";

export type ContactList = {
  contactListId: string;
  completed: boolean;
};

export type Contact = {
  accountName: string;
  contactListId: string;
  crmId: string;
  email: string;
  jobTitle: string;
  name: string;
  notes: string;
  phone: string;
};

export type CallSession = {
  sdrId: string;
  sdrStatus: string;
  status: string;
  startAt: string;
  contactList: [ContactList];
  totalContacts: number;
  contactListName: [string];
  previewContacts: [Contact];
  voiceDropsIds: [string];
  voiceDropsTitles: [string];
  scriptTitle: string;
  speech: string;
  agents: [string];
  scriptId: string;
};

export type LocationState = {
  callSession: CallSession;
};

export enum Action {
  TRANSFERRED = 'transferred',
  PAUSED = 'paused',
  STARTED = 'started',
  FINISHED = 'finished',
}

export type CallSessionAction = {
  action: Action;
  userId: string;
}

export type UpdateCallSessionStatus = {
  status: string,
  sdrStatus: string | null;
  action?: CallSessionAction
}

export type CallSessionSuccessResponse = {
  id: string;
  sdrId: string;
  status: string;
  action: CallSessionAction;
  sdrStatus: string;
  startAt: number;
  endsAt: number;
  contactList: [ContactList];
  voiceDropsIds: [string];
  speech: string;
  agents: [string];
  scriptId: string;
  scriptTitle: string;
};

export interface VoiceMailList {
  id: string;
  url: string;
  callSessionId: string;
  sdrId: string;
  callId: string;
  title: string;
}

export type TwilioTokenSuccessResponse = {
  token: string;
  agentId: string;
};

export class MultipleStatusAndSdrIdResponse {
  callSession!: CallSessionSuccessResponse;
  queueLength!: number;
}

export class DialingStatusResponse {
  condenseInfo!: [ContactDialingStatus];
  queueLength!: number;
}

export class CreateCallSessionResponse {
  callSession!: CallSessionSuccessResponse;
  queueLength!: number;
}
