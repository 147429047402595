import getDashboardDataAsync from '@datasource/contacts-nest/dashboard/getDashboardData';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type DashboardResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardResponse';
import { type PaginatedDashboardDetailResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardSessionDetail';
import getDashboardDetailsAsync from '@datasource/contacts-nest/dashboard/getDashboardDetails';
import getSessionRecordingsAsync, {
  type CallRecording,
} from '@datasource/contacts-nest/dashboard/getSessionRecordings';

interface DashboardRepoProps {
  getDashboardData: (
    sdrId: string,
    params: {
      page: number;
      pageLimit: number;
      searchQuery?: string;
      sdrIds?: string[];
      organizationIds?: string[];
    },
  ) => Promise<ApiResponse<DashboardResponse>>;
  getDashboardDetails: (
    callSessionId: string,
    userId: string,
    page: number,
    limit: number,
  ) => Promise<ApiResponse<PaginatedDashboardDetailResponse>>;
  getSessionRecordings: (callId: string, userId: string) => Promise<ApiResponse<Array<CallRecording | null>>>;
}

function DashboardRepository(): DashboardRepoProps {
  return {
    async getDashboardData(sdrId: string, params) {
      const { data, success, error } = await getDashboardDataAsync(sdrId, params);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getDashboardDetails(callSessionId: string, userId: string, page: number, limit: number) {
      const { data, success, error }: ApiResponse<PaginatedDashboardDetailResponse> = await getDashboardDetailsAsync(
        callSessionId,
        userId,
        page,
        limit,
      );
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getSessionRecordings(callId: string, userId: string) {
      const { data, success, error } = await getSessionRecordingsAsync(callId, userId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
export default DashboardRepository;
