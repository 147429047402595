import axios, { AxiosInstance } from "axios";
import routes from "../../constants/routes";
import { useLogger } from "../../components/app/LoggerProvider";
import {useContext, useEffect, useRef} from "react";
import {UserProviderContext} from "../../components/app/UserProvider";

interface ApiResponse<T> {
  data?: T;
  success?: boolean;
  error?: { message: string };
}

interface CredentialsDto {
  email: string,
  password: string
}

interface AuthenticationResponse {
  access_token: string,
  status: string
}

interface UserData {
  createdAt: Date,
  email: string,
  external_id: string,
  id: string,
  updatedAt: Date
}

interface UserDataResponse {
  data: UserData,
  status: string
}

export interface LogoutResponse {
  success: string;
}

const CONFIG = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
}

export default function useAuthApi() {

  const axiosInstance: AxiosInstance = axios.create();
  const {logger} =  useLogger();
  const {currentUser}  = useContext(UserProviderContext);
  const loggerRef = useRef(logger);


  useEffect(() => {
    loggerRef.current = logger;
  }, [logger]);

  async function login(credentials: CredentialsDto): Promise<ApiResponse<AuthenticationResponse>> {
    try {
      const response = await axios.post(routes.post.auth.authLogin, credentials, CONFIG)

      return {
        success: true,
        data: response.data as AuthenticationResponse,
      };
    } catch (e: unknown) {
      return {
        success: false,
        error: {
          message:
              "An error occurred while login in. If the problem persists, please reach tech support",
        },
      };
    }
  }

  async function logout(): Promise<ApiResponse<LogoutResponse>> {
    try {
      const session = await axiosInstance.get(routes.get.auth.logout, CONFIG)

      return {
        success: true,
        data: session.data,
      };
    } catch (e: unknown) {
      return {
        success: false,
        error: {
          message:
              "An error occurred while signing out. If the problem persists, contact tech support.",
        },
      };
    }
  }

  async function refresh(): Promise<ApiResponse<AuthenticationResponse>> {
    const queryParameters = window.location.pathname;
    if(queryParameters !== '/login') {
    try {
      const response = await axiosInstance.get<AuthenticationResponse>(routes.get.auth.refreshSession, CONFIG)

      return {
        success: true,
        data: response.data as AuthenticationResponse,
      };
    } catch (e: unknown) {
      return {
        success: false,
        error: {
          message:
              "An error occurred while attempting to refresh the session. If the problem persists, contact tech support.",
        },
      };
    }
    } else {
      return {success: false};
    }
  }

  async function getMe(): Promise<ApiResponse<UserDataResponse>> {
    try {
      const response = await axios.get<UserDataResponse>(routes.get.auth.getMe, CONFIG);

      return {
        success: true,
        data: response.data as UserDataResponse,
      };
    } catch (e: unknown) {
      loggerRef.current.error('An error occurred while checking the session. Please login again or refresh the session.', {
        componentName: useAuthApi.name,
        userId: currentUser.userId,
      })
      return {
        success: false,
        error: {
          message:
              "An error occurred while checking the session. Please login again or refresh the session.",
        },
      };
    }
  }

  return {
    login,
    logout,
    refresh,
    getMe
  }
}