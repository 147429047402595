import { type ApiResponse } from '@/src/types/ApiResponse';

import getLoggerTokenAsync from '@/src-v2/Data/DataSource/edge/auth/getLoggerTokenAsync';
import { logoutEdgeAsync } from '@datasource/edge/auth/logoutEdgeAsync';
import { refreshTokenEdgeAsync, type TokenRefreshResponse } from '@datasource/edge/auth/refreshTokenEdgeAsync';
import { type TokenObject } from '@datasource/edge/auth/dto/refreshToken.dto';

interface AuthRepo {
  getLoggerTokenAsync: () => Promise<ApiResponse<string>>;
  edgeLogout: (token: string) => Promise<ApiResponse<string>>;
  edgeRefreshToken: (token: TokenObject) => Promise<ApiResponse<TokenRefreshResponse>>;
}

export function AuthRepository(): AuthRepo {
  return {
    async getLoggerTokenAsync() {
      const { data, success, error } = await getLoggerTokenAsync();
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async edgeLogout(token): Promise<ApiResponse<string>> {
      const { data, success, error } = await logoutEdgeAsync(token);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async edgeRefreshToken(token): Promise<ApiResponse<TokenRefreshResponse>> {
      const { data, success, error } = await refreshTokenEdgeAsync(token);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
  };
}
