import { type ChangeEvent, useEffect, useState } from 'react';
import { type SafeUserDto } from '@datasource/edge/users/dto/safeUser.dto';
import { type Timezone } from '@domain/enums/Users/timezone';
import { UsersUseCase } from '@usecase/users/UsersUseCase';
import { showUIToast, UI_TOAST_TYPES } from '@/src/core/ui/UIToast';
import { useOutletContext } from 'react-router-dom';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import { type CurrentUser } from '@/src/types';

interface NavImageView {
  showAvatarModal: boolean;
  actualPicture: string;
  hasImage: boolean;
}

interface NavBarViewModelProps {
  uploadPicture: () => void;
  removePicture: () => void;
  selectUserImage: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultImageUri: string;
  avatarViewControl: NavImageView;
  currentUser: CurrentUser;
}
export function NavBarViewmodel(): NavBarViewModelProps {
  const { userSession: currentUser } = useOutletContext<OutletContextProps>();
  const [selectedImage, selectImage] = useState<File>();
  const defaultImageUri = 'tondril-dude.svg';
  const [avatarViewControl, editAvatarView] = useState<NavImageView>({
    showAvatarModal: false,
    actualPicture: defaultImageUri,
    hasImage: false,
  });
  const [user, setUser] = useState<SafeUserDto>({
    email: '',
    id: '',
    name: '',
    organizationId: '',
    profilePicture: null,
    sdrId: null,
    timezone: '' as Timezone,
    userType: [],
  });
  const { getUserAsync, updateUserData, uploadProfilePicture } = UsersUseCase();

  function selectUserImage(e: ChangeEvent<HTMLInputElement>): void {
    if (e.target.files !== null) {
      const file = e.target.files[0];

      const imageUrl = URL.createObjectURL(file);
      selectImage(file);
      editAvatarView({ ...avatarViewControl, showAvatarModal: true, actualPicture: imageUrl });
    }
  }

  function uploadPicture(): void {
    if (selectedImage === undefined) {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Your picture could not be uploaded',
      });
    } else {
      void uploadProfilePicture(currentUser.userId, { file: selectedImage }).then((upload) => {
        void getUserAsync(currentUser.userId).then((userResponse) => {
          editAvatarView({ ...avatarViewControl, hasImage: true, showAvatarModal: false });

          if (upload.success !== false && userResponse.data !== undefined) {
            setUser(userResponse.data);
            editAvatarView({
              showAvatarModal: false,
              actualPicture: user.profilePicture as string,
              hasImage: user.profilePicture?.includes(defaultImageUri) as boolean,
            });

            showUIToast({
              type: UI_TOAST_TYPES.INFO,
              text: 'Your profile picture was successfully uploaded',
            });
            window.location.reload();
          }
        });
      });
    }
  }

  function removePicture(): void {
    void getUserAsync(currentUser.userId).then((userData) => {
      if (userData.data !== undefined) {
        const { id, email, name, organizationId, userType, sdrId, timezone } = userData.data;

        void updateUserData({
          email,
          id,
          name,
          organizationId,
          profilePicture: defaultImageUri,
          sdrId,
          timezone,
          userType,
        }).then(() => {
          window.location.reload();
          showUIToast({
            type: UI_TOAST_TYPES.INFO,
            text: 'Your profile picture was successfully removed',
          });
        });
      }
    });
  }

  useEffect(() => {
    void getUserAsync(currentUser.userId).then((response) => {
      if (response.data !== undefined) {
        editAvatarView({
          ...avatarViewControl,
          actualPicture: response.data.profilePicture as string,
          hasImage: !(response.data.profilePicture as string).includes(defaultImageUri),
        });
      }
    });
  }, []);

  return {
    uploadPicture,
    removePicture,
    selectUserImage,
    defaultImageUri,
    avatarViewControl,
    currentUser,
  };
}
