export type ParentGuardian = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  relationToChild?: string;
};

export enum Gender {
  Female = "F",
  Male = "M",
  Other = "O",
}

export type ContactMetadata = {
  externalId?: string;
  ssid?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  gender?: string;
  grade?: number;
  returningHBA?: string;
  leavingReason?: string;
  firstParentGuardian?: ParentGuardian;
  secondParentGuardian?: ParentGuardian;
  phoneTwo?: string;
  contactNameTwo?: string;
  homeAddress?: string;
  homeLineTwo?: string;
  homeCity?: string;
  homeZip?: string;
  transportationStatus?: string;
  amAddress?: string;
  lineTwo?: string;
  amCity?: string;
  amZip?: string;
  pmAddress?: string;
  pmLineTwo?: string;
  pmCity?: string;
  pmZip?: string;
  daysOfWeek?: string[];
  incomingSibling?: string;
  additionalNotes?: string;
};

export type Contact = {
  id: string;
  contactListId: string;
  name: string;
  email: string;
  jobTitle: string;
  accountName: string;
  phone: string;
  phoneExtension?: string;
  notes: string;
  notesAgent: string;
  notesSDR: string;
  flagged: boolean;
  _id: string;
  type: string;
  meta?: ContactMetadata | null;
};
