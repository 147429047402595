import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@components/ui/alert-dialog';
import { Button } from '@components/ui/button';
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconX } from '@tabler/icons-react';
import React, { type Dispatch, type FC, type SetStateAction, useRef, useState } from 'react';
import { toast } from '@components/ui/use-toast';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

interface RecordingPlayerProps {
  trigger: React.JSX.Element;
  sdrAudio: string;
  agentAudio: string;
  sdrAudioDuration: string;
  agentAudioDuration: string;
  opened: boolean;
  setOpenedModal: Dispatch<SetStateAction<boolean>>;
  setSdrAudio: Dispatch<SetStateAction<string>>;
  setAgentAudio: Dispatch<SetStateAction<string>>;
}
const RecordingPlayerComponent: FC<RecordingPlayerProps> = ({
  trigger,
  sdrAudio,
  agentAudio,
  sdrAudioDuration,
  agentAudioDuration,
  opened,
  setOpenedModal,
  setSdrAudio,
  setAgentAudio,
}) => {
  const [isSdrPlaying, setSdrPlaying] = useState(false);
  const [isAgentPlaying, setAgentPlaying] = useState(false);

  const sdrDurationRef = useRef(0);
  const agentDurationRef = useRef(0);

  const sdrRef = useRef<HTMLAudioElement>(null);
  const agentRef = useRef<HTMLAudioElement>(null);

  const cellBorder = 'border-2 border-secondary';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call

  /***
   Void function to check the advance from the audio recording.
   @Params audioRef an HTML audio element to extract the duration of an audio; durationRef: a mutable number to change while the audio plays,
    isPlaying: a boolean which detects play of pause behavior
   @Description
   Extracts the audio duration and transforms it into a discrete value, as duration values are usually decimal values.
   Uses an interval, if the state is "Playing" takes the audio duration and decreases by 1 each second (1000ms) the duration
   until 0 is reached, then the "isPlayed" hook is set to false and drops the interval.
   Else if the state changes to "Paused" the duration value is set to 1 decreased by 1, reaching 0 and drops the interval almost immediately(100ms).
   */
  function detectAudioStop(
    audioRef: React.RefObject<HTMLAudioElement>,
    durationRef: React.MutableRefObject<number>,
    isPlaying: boolean,
    setPlaying: Dispatch<SetStateAction<boolean>>,
  ): void {
    durationRef.current = Math.ceil(audioRef?.current?.duration as number);
    const audioAdvance = setInterval(() => {
      if (!isPlaying) {
        durationRef.current = durationRef.current - 1;

        if (durationRef.current === 0) {
          clearInterval(audioAdvance);
          setPlaying(false);
        }
      } else {
        durationRef.current = 1;
        clearInterval(audioAdvance);
        setPlaying(false);
        toast({ variant: 'default', title: 'Paused', description: 'Call Recording was paused' });
      }
    }, 1000);
  }

  function toggleSdrPlayPause(): void {
    if (isAgentPlaying) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You cannot play both recordings at the same time',
      });
      return;
    }

    isSdrPlaying ? sdrRef?.current?.pause() : sdrRef?.current?.play();
    isSdrPlaying ? setSdrPlaying(false) : setSdrPlaying(true);

    toast({ variant: 'default', title: 'Playing', description: 'Playing Call Recording' });
    detectAudioStop(sdrRef, sdrDurationRef, isSdrPlaying, setSdrPlaying);
  }

  function toggleAgentPlayPause(): void {
    if (isSdrPlaying) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You cannot play both recordings at the same time',
      });
      return;
    }

    isAgentPlaying ? agentRef?.current?.pause() : agentRef?.current?.play();
    isAgentPlaying ? setAgentPlaying(false) : setAgentPlaying(true);

    toast({ variant: 'default', title: 'Playing', description: 'Playing Call Recording' });
    detectAudioStop(agentRef, agentDurationRef, isAgentPlaying, setAgentPlaying);
  }

  return (
    <AlertDialog open={opened}>
      <AlertDialogTrigger asChild>
        <div className="flex justify-center max-w-12 cursor-pointer">{trigger}</div>
      </AlertDialogTrigger>
      <AlertDialogContent className="px-2 py-4">
        <AlertDialogHeader className={'flex items-center'}>
          <div className="flex flex-row items-center gap-60 justify-between">
            <AlertDialogTitle className="text-app-blue-light">Play recordings</AlertDialogTitle>
            <AlertDialogCancel className="border-none">
              <IconX
                color="hsl(var(--primary))"
                onClick={() => {
                  setOpenedModal(false);
                  setSdrAudio('');
                  setAgentAudio('');
                  setSdrPlaying(false);
                  setAgentPlaying(false);
                }}
              />
            </AlertDialogCancel>
          </div>
        </AlertDialogHeader>
        <div className="flex flex-row p-4 justify-center">
          <table className="w-3/4 border-t-gray-500">
            <thead>
              <tr className="text-center">
                <td className={cellBorder}>Call from</td>
                <td className={cellBorder}>Duration (seconds)</td>
                <td className={cellBorder}>Play</td>
              </tr>
            </thead>
            <tbody>
              {agentAudio !== '' && (
                <tr className="text-center">
                  <td className={cellBorder}>
                    <b>Agent</b>
                  </td>
                  <td className={cellBorder}>
                    <h3>{moment.duration(agentAudioDuration, 'seconds').format()}</h3>
                  </td>
                  <td className={cellBorder}>
                    <audio id="agentRecording" ref={agentRef}>
                      <source src={agentAudio} />
                    </audio>
                    <Button
                      size="sm"
                      className="rounded-full"
                      onClick={() => {
                        toggleAgentPlayPause();
                      }}
                    >
                      {isAgentPlaying ? (
                        <IconPlayerPauseFilled color="white" size={16} />
                      ) : (
                        <IconPlayerPlayFilled color="white" size={16} />
                      )}
                    </Button>
                  </td>
                </tr>
              )}
              {sdrAudio !== '' && (
                <tr className="text-center">
                  <td className={cellBorder}>
                    <b>User</b>
                  </td>
                  <td className={cellBorder}>
                    <h3>{moment.duration(sdrAudioDuration, 'seconds').format()}</h3>
                  </td>
                  <td className={cellBorder}>
                    <audio id="sdrRecording" ref={sdrRef}>
                      <source src={sdrAudio} />
                    </audio>
                    <Button
                      id="sdrRecordingButton"
                      size="sm"
                      className="rounded-full"
                      onClick={() => {
                        toggleSdrPlayPause();
                      }}
                    >
                      {isSdrPlaying ? (
                        <IconPlayerPauseFilled color="white" size={16} />
                      ) : (
                        <IconPlayerPlayFilled color="white" size={16} />
                      )}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RecordingPlayerComponent;
