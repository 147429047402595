import { useContext, useEffect, useState } from "react";
import { showUIToast } from "../ui/UIToast";
import { UserProviderContext } from "../../components/app/UserProvider";

interface CustomWindow extends Window {
  localStream?: MediaStream;
}

declare const window: CustomWindow;

interface Microphone {
  muteMicrophone: () => void;
  unmuteMicrophone: () => void;
  isMicrophoneOn: boolean;
  isMicrophoneAllowed: boolean;
  setupMicrophonePermissions: (
    setupMicrophoneSettings?: SetupMicrophoneSettings
  ) => Promise<MediaStreamResponse>;
}

interface MediaStreamResponse {
  stream?: MediaStream;
  error?: {
    message: string;
  };
}

interface SetupMicrophoneSettings {
  showToast?: boolean;
}

function useMicrophone(): Microphone {
  const { currentUser } = useContext(UserProviderContext);

  const [isMicrophoneOn, setMicrophoneOn] = useState<boolean>(true);
  const [isMicrophoneAllowed, setMicrophoneAllowed] = useState<boolean>(true);

  const isFeatureAvailable = (): boolean => {
    const isLocalHost = window.location.hostname === "localhost";
    const isHttps = window.location.protocol === "https:";

    const isMicrophoneCheckAvailable = isHttps || isLocalHost;

    if (!isMicrophoneCheckAvailable) {
      showUIToast({
        type: "info",
        text: `Microphone Permission check is not available.`,
      });
    }

    return isMicrophoneCheckAvailable;
  };

  const resetPermissions = (): void => {
    window.localStream?.getTracks().forEach((track) => track.stop());
    window.localStream = undefined;
    setMicrophoneAllowed(false);
  };

  const setupMicrophonePermissions = async (
    setupMicrophoneSettings?: SetupMicrophoneSettings
  ): Promise<MediaStreamResponse> => {
    const { showToast = false } = setupMicrophoneSettings || {};
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });

      window.localStream = stream;

      showToast &&
        showUIToast({
          type: "info",
          text: "Microphone is ready.",
        });

      setMicrophoneAllowed(true);

      return {
        stream,
      };
    } catch (err) {
      showToast &&
        showUIToast({
          type: "warning",
          text: "Microphone is not ready. Please check your browser settings.",
        });

      setMicrophoneAllowed(false);

      resetPermissions();

      return {
        error: {
          message:
            "Could not get microphone permissions. Please check your browser settings.",
        },
      };
    }
  };

  const validateCurrentBrowserSettings = async (): Promise<void> => {
    if (window.localStream) {
      setMicrophoneAllowed(true);
    } else {
      await setupMicrophonePermissions();
    }
  };

  const muteMicrophone = () => {
    setMicrophoneOn(false);
  };

  const unmuteMicrophone = () => {
    setMicrophoneOn(true);
  };

  useEffect(() => {
    if (!isFeatureAvailable()) {
      return;
    }
    if (isMicrophoneOn) {
      setupMicrophonePermissions().finally();
    } else {
      resetPermissions();
    }
  }, [isMicrophoneOn]);

  useEffect(() => {
    if (!isFeatureAvailable()) {
      return;
    }
    currentUser.userId && validateCurrentBrowserSettings().finally();
  }, [isMicrophoneOn, isMicrophoneAllowed, currentUser.userId]);

  return {
    muteMicrophone,
    unmuteMicrophone,
    isMicrophoneOn,
    isMicrophoneAllowed,
    setupMicrophonePermissions,
  };
}

export default useMicrophone;
