import { type IconName } from '@fortawesome/fontawesome-svg-core';

export interface SidebarItemData {
  anchor?: string;
  title: string;
  path: string;
  icon: IconName;
  restrictedToAdmin?: boolean;
  isRedirecting?: boolean;
  isHidden?: boolean;
  isBeta?: boolean;
}
export const SidebarData: SidebarItemData[] = [
  {
    title: 'Home',
    path: '/home',
    icon: 'house',
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    icon: 'address-book',
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Voice',
    path: '/voice',
    icon: 'volume-high',
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Intent',
    anchor: 'Script',
    path: '/script',
    icon: 'comments-dollar',
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'chart-pie',
    isRedirecting: true,
    isHidden: true,
  },
  {
    title: 'Dashboard',
    path: '/dashboard-v2',
    icon: 'chart-pie',
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Management',
    path: '/management',
    icon: 'bars-progress',
    restrictedToAdmin: true,
    isRedirecting: true,
    isHidden: false,
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'gear',
    isRedirecting: true,
    isHidden: false,
  },
];
