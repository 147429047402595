import { type ApiResponse } from '@/src/types/ApiResponse';
import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';
import getAllOrganizationsAsync from '@datasource/edge/organizations/getAllOrganizations';

interface OrganizationsRepoProps {
  getAllOrganizations: () => Promise<ApiResponse<ActiveOrganizations[]>>;
}

function OrganizationsRepository(): OrganizationsRepoProps {
  return {
    async getAllOrganizations() {
      const { data, success, error } = await getAllOrganizationsAsync();
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

export default OrganizationsRepository;
