import { type ApiResponse } from '@/src/types/ApiResponse';
import { type ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import axios from 'axios';
import routes from '@/src/constants/routes';

async function getContactsByContactListIdAsync(contactListId: string): Promise<ApiResponse<ContactInfo[]>> {
  try {
    const contactList: ApiResponse<ContactInfo[]> = await axios.get(
      `${routes.get.contacts.contactsNest}/${contactListId}`,
    );

    return { data: contactList.data, success: true };
  } catch {
    return { error: { message: 'Error getting contacts by Contact List id' }, success: false };
  }
}
export default getContactsByContactListIdAsync;
