import React, { type FC, useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@components/ui/alert-dialog';
import Counter from '@/src-v2/Presentation/Common/Components/customElements/Counter';
import LayoutSessionTokensUseCase from '@usecase/layout/layoutSessionTokensUseCase';
import { toast } from '@components/ui/use-toast';
import { PublicRoutes } from '@/src/constants/routesConnect';
import { useNavigate } from 'react-router-dom';
import { LayoutViewModel } from '@views/Layout/Layout.viewmodel';

const TimeoutAlertComponent: FC = () => {
  const { edgeRefreshToken } = LayoutSessionTokensUseCase();
  const { logoutUser } = LayoutViewModel();
  const navigate = useNavigate();
  const [openedModal, openModal] = useState(true);
  const [remainingTTL, setRemainingTTL] = useState(30);

  function refreshToken(): void {
    const token = window.localStorage.getItem('target');
    openModal(false);
    if (token !== null) {
      void edgeRefreshToken({ token }).then((response) => {
        response.data !== undefined
          ? window.localStorage.setItem('target', response.data.authToken)
          : window.localStorage.setItem('target', '');

        if (response.success === false) {
          toast({ title: 'Error', description: 'Could not validate session. Logging out', variant: 'destructive' });
          navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
        }
        toast({ title: 'Success!', description: 'Your session has been refreshed', variant: 'default' });
      });
    }
  }

  useEffect(() => {
    if (remainingTTL === 0) {
      logoutUser();
    }
  }, [remainingTTL]);

  return (
    <AlertDialog open={openedModal}>
      <AlertDialogContent className={!openedModal ? 'hidden' : ''}>
        <AlertDialogHeader className="flex items-center">
          <AlertDialogTitle>Your session will expire soon.</AlertDialogTitle>
          <AlertDialogDescription>
            {remainingTTL !== 0 && <Counter remainingTTL={remainingTTL} setRemainingTTL={setRemainingTTL} />}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex items-center">
          <AlertDialogAction
            className="transition duration-700 border-none bg-accent text-secondary-foreground hover:bg-accent hover:text-destructive"
            onClick={logoutUser}
          >
            Log out
          </AlertDialogAction>
          <AlertDialogCancel
            className="transition duration-700 border-none bg-primary text-primary-foreground"
            onClick={refreshToken}
          >
            Stay Login
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default TimeoutAlertComponent;
