import { Badge } from '@components/ui/badge';
import React from 'react';

function BetaFeatureLabel(): React.JSX.Element {
  return (
    <span className="flex">
      <Badge variant="outline" className="bg-app-blue-lighter text-white border-sky-200">
        Beta
      </Badge>
    </span>
  );
}

export default BetaFeatureLabel;
