import React, { useState, createContext, ReactElement, useEffect } from "react";
import { UserTypes } from "../shared/enums";
import { CurrentUser } from "../../types";
import { UsersUseCase } from "@/src-v2/Domain/UseCase/users/UsersUseCase";
import { LoadingState } from "@/src/types/LoadingStates.type";
import axios from "axios";

type UserProviderContextProps = {
  currentUser: CurrentUser;
  setCurrentUser(user: CurrentUser): void;
  clearCurrentUser(): void;
};

export const UserProviderContext = createContext<UserProviderContextProps>({
  currentUser: {} as CurrentUser,
  setCurrentUser: () => {
    /* only for declaration purposes */
  },
  clearCurrentUser: () => {
    /* only for declaration purposes */
  },
});

type Props = {
  children: ReactElement;
};

const getTypes = (user: CurrentUser) => {
  const types = user.type.map((t) => t.type);
  return {
    isAdmin: types.includes(UserTypes.ADMIN),
    isSdr: types.includes(UserTypes.SDR),
    isAgent: types.includes(UserTypes.AGENT),
  };
};

const { getUserAsync } = UsersUseCase();

interface IRetrieveUserLoadingState {
  retrieveUser: LoadingState;
}

const UserProvider = ({ children }: Props): JSX.Element => {
  const [user, setUser] = useState<CurrentUser>(() => {
    const storedUser = localStorage.getItem("contextUser");
    return storedUser ? JSON.parse(storedUser) : ({} as CurrentUser);
  });

  const [token, setToken] = useState<string | null>(() => {
    const storedTarget = localStorage.getItem("target");
    return storedTarget ? storedTarget : null;
  });

  const { LOADING, SUCCESS } = LoadingState;

  const [loadingState, setLoadingState] = useState<IRetrieveUserLoadingState>({
    retrieveUser: LOADING,
  });

  useEffect(() => {

      const retrieveUserFromStorage = async () => {

      if(!token){

        localStorage.removeItem('contextUser');

        return setLoadingState((prevState) => ({
          ...prevState,
          retrieveUser: SUCCESS,
        }));

      }

      if(loadingState.retrieveUser !== SUCCESS || !Object.keys(user).length) {

      const storedUser = localStorage.getItem("contextUser");

      if (storedUser && token) {
        const parsedUser: CurrentUser = JSON.parse(storedUser);

        setUser(parsedUser);

        setCurrentUser(parsedUser);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        setLoadingState((prevState) => ({
          ...prevState,
          retrieveUser: SUCCESS,
        }));
      }

    };

    };

    retrieveUserFromStorage();

  }, [user, loadingState.retrieveUser]);

  useEffect(() => {

    if( user !== null && Object.keys(user)){

      localStorage.setItem("contextUser", JSON.stringify(user));
      
    }

  }, [user]);

  // Add here all the user info you want to have at the context
  const setCurrentUser = async (user: CurrentUser) => {

    // Add a validation to be sure the current user is not empty
    if(!Object.keys(user).length) {
      return;
    }
    const types = getTypes(user);

    const userContext = { ...user, ...types };

    if (types.isAgent && user.sdrId) {
      const response = await getUserAsync(user.sdrId);
      const sdrName: string | undefined = response.data?.name;
      if (sdrName) {
        userContext.sdrName = sdrName;
      }
    }

    setUser(userContext);
  };

  const clearCurrentUser = () => {
    localStorage.removeItem("contextUser");
    setUser({} as CurrentUser);
  };

  return (
    <UserProviderContext.Provider
      value={{ currentUser: user, setCurrentUser, clearCurrentUser }}
    >
      { loadingState.retrieveUser === SUCCESS && children }
    </UserProviderContext.Provider>
  );
};

export default UserProvider;