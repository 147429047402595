import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type PaginatedDashboardDetailResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardSessionDetail';

async function getDashboardDetailsAsync(
  callSessionId: string,
  userId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedDashboardDetailResponse>> {
  try {
    const callSessionDetails: ApiResponse<PaginatedDashboardDetailResponse> =
      await axios.get<PaginatedDashboardDetailResponse>(
        `${String(routes.get.dashboard.getSessionDetails)}/${callSessionId}/user/${userId}?page=${page}&limit=${limit}`,
      );

    return { data: callSessionDetails.data, success: true };
  } catch {
    return { error: { message: `Could not retrieve Session details for id: ${callSessionId}` }, success: false };
  }
}

export default getDashboardDetailsAsync;
