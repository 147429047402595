import getCallSessionsBySdrId from '@datasource/contacts-nest/callSession/getCallSessionsBySdrId';
import {
  type AgentCallInfo,
  type ActiveCallSession,
  type CallSession,
  type MultipleStatusAndSdrIdResponse,
} from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import getActiveCallSessions from '@datasource/contacts-nest/callSession/getActiveCallSessions';
import updateCallSession from '@datasource/contacts-nest/callSession/updateCallSession';
import { type UpdateCallSessionDto } from '@datasource/contacts-nest/callSession/entities/updateCallSessionDto';
import getCallSessionById from '@datasource/contacts-nest/callSession/getCallSessionById';
import getAgentsCallInfo from '@/src-v2/Data/DataSource/contacts-nest/callSession/getAgentsCallInfo';
import deletAgentsFromSessionBySessionId from '@/src-v2/Data/DataSource/contacts-nest/callSession/deleteAgentsFromSessionBySessionId';
import { getByMultipleStatusAndSdrIdAsync } from '@datasource/contacts-nest/callSession/getByMultipleStatusAndSdrId';
import getSessionReportAsync from '@datasource/contacts-nest/callSession/getSessionReport';

interface CallSessionRepo {
  getCallSessionsBySdrId: (sdrId: string, orgId: string) => Promise<ApiResponse<CallSession[]>>;
  getAgentsCallInfo: (sessionId: string) => Promise<ApiResponse<AgentCallInfo[] | undefined>>;
  getCallSessionById: (callSessionId: string) => Promise<ApiResponse<CallSession>>;
  getActiveCallSessions: () => Promise<ApiResponse<ActiveCallSession[]>>;
  updateCallSession: (callSessionId: string, payload: UpdateCallSessionDto) => Promise<ApiResponse<CallSession>>;
  deleteAgentsBySessionId: (CallSessionId: string) => Promise<ApiResponse<CallSession>>;
  getByMultipleStatusAndSdrId: (
    status: string[],
    sdrId: string,
  ) => Promise<ApiResponse<MultipleStatusAndSdrIdResponse>>;
  getSessionReport: (callSessionId: string, type: string) => Promise<ApiResponse<string>>;
}

export function CallSessionRepository(): CallSessionRepo {
  return {
    async getCallSessionsBySdrId(sdrId: string, orgId: string) {
      const { data, error, success } = await getCallSessionsBySdrId(sdrId, orgId);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getAgentsCallInfo(sessionId: string) {
      return await getAgentsCallInfo(sessionId);
    },

    async getCallSessionById(callSessionId: string): Promise<ApiResponse<CallSession>> {
      const { data, success, error } = await getCallSessionById(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getActiveCallSessions() {
      const { data, success, error } = await getActiveCallSessions();
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async updateCallSession(callSessionId: string, payload: UpdateCallSessionDto) {
      const { data, success, error } = await updateCallSession(callSessionId, payload);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async deleteAgentsBySessionId(callSessionId: string) {
      const { data, success, error } = await deletAgentsFromSessionBySessionId(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getByMultipleStatusAndSdrId(status: string[], sdrId: string) {
      const response = await getByMultipleStatusAndSdrIdAsync(status, sdrId);
      const { data, success, error } = response;
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getSessionReport(callSessionId: string, type: string) {
      const response = await getSessionReportAsync(callSessionId, type);
      const { data, success, error } = response;
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
