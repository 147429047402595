import React, { type FC } from 'react';
import { Separator } from '@components/ui/separator';

interface SettingsTitleDividerProps {
  title: string;
  description: string;
  secondDescription?: string | undefined;
  thirdDescription?: string | undefined;
}

const SettingsTitleDivider: FC<SettingsTitleDividerProps> = ({
  title,
  description,
  secondDescription,
  thirdDescription,
}) => {
  return (
    <>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium">{title}</h3>
          <p className="text-sm text-muted-foreground">{description}</p>
          {secondDescription !== undefined && (
            <p className="text-sm text-muted-foreground">{secondDescription ?? ''}</p>
          )}
          {thirdDescription !== undefined && <p className="text-sm text-muted-foreground">{thirdDescription ?? ''}</p>}
        </div>
        <Separator />
      </div>
    </>
  );
};

export default SettingsTitleDivider;
