import routes from "../../constants/routes";
import axios, { AxiosResponse } from "axios";
import { Organization } from "../../types";


export type UserResponseProps = {
  organization?: Organization;
  error?: { message: string };
};

const getOrganizationInfo = async (
  organizationId: string
): Promise<UserResponseProps> => {
  try {
    const orgUrl = `${routes.get.organizations.getById}/${organizationId}`;
    const organization: AxiosResponse<Organization> = await axios.get(orgUrl);
    return { organization: organization.data };
  } catch (e) {
    return { error: { message: "Error getting the requested organization" } };
  }
};

export default getOrganizationInfo;
