import { type ApiResponse } from '@/src/types/ApiResponse';
import { type DashboardSearchResponse } from '@datasource/edge/dashboard/entities/dashboardSearchResponse';
import searchDashboardDataAsync from '@datasource/edge/dashboard/searchDashboardData';

interface EdgeDashboardRepoProps {
  searchInfoByName: (query: string) => Promise<ApiResponse<DashboardSearchResponse>>;
}
function EdgeDashboardRepository(): EdgeDashboardRepoProps {
  return {
    async searchInfoByName(query: string): Promise<ApiResponse<DashboardSearchResponse>> {
      const { data, success, error } = await searchDashboardDataAsync(query);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

export default EdgeDashboardRepository;
