import React from "react";
import GenericModal from "../../shared/GenericModal";
import CreateEditUserForm from "./CreateEditUserForm";
import { Text } from "../../../core/ui";
import { User } from "../../../types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedUser: User;
  onSuccess: () => void;
  isEditing: boolean;
};

const CreateEditModal = ({
  isOpen,
  onClose,
  selectedUser,
  onSuccess,
    isEditing,
}: Props) => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    padding={20}
    overflow="hidden!important"
  >
    <Text
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      color="black"
      mb={40}
    >
      {selectedUser ? "Edit user" : "Create user"}
    </Text>
    <Text color="black" mb={15} textAlign="center">
      {selectedUser
        ? `Please edit ${selectedUser.name} data`
        : "Please fill all the fields"}
    </Text>
    <CreateEditUserForm user={selectedUser} onSuccess={onSuccess} isEditing={isEditing}/>
  </GenericModal>
);

export default CreateEditModal;
