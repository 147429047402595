import { type ApiResponse } from '@/src/types/ApiResponse';
import { getByMultipleStatusAndSdrIdAsync } from '@datasource/contacts-nest/callSession/getByMultipleStatusAndSdrId';
import { type BuiltMetric } from '@datasource/contacts-nest/metrics/entities/metrics.entity';
import getBuiltMetricsAsync from '@datasource/contacts-nest/metrics/getBuiltMetrics';
import { type MultipleStatusAndSdrIdResponse } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import getContactsByContactListId from '@datasource/contacts-nest/contacts/getContactsByContactListId';

interface LayoutUseCaseProps {
  getByMultipleStatusAndSdrId: (
    status: string[],
    sdrId: string,
  ) => Promise<ApiResponse<MultipleStatusAndSdrIdResponse>>;
  getBuiltMetrics: (callSessionId: string) => Promise<ApiResponse<BuiltMetric>>;
  getContactsByContactListId: (contactListId: string) => Promise<ApiResponse<ContactInfo[]>>;
}

export function LayoutMetricsUseCase(): LayoutUseCaseProps {
  return {
    async getByMultipleStatusAndSdrId(
      status: string[],
      sdrId: string,
    ): Promise<ApiResponse<MultipleStatusAndSdrIdResponse>> {
      const { data, success, error } = await getByMultipleStatusAndSdrIdAsync(status, sdrId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getBuiltMetrics(callSessionId: string): Promise<ApiResponse<BuiltMetric>> {
      const { data, success, error } = await getBuiltMetricsAsync(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getContactsByContactListId(contactListId: string): Promise<ApiResponse<ContactInfo[]>> {
      const { data, error, success } = await getContactsByContactListId(contactListId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
