import React, { type FC } from 'react';
import IdleTimer from 'react-idle-timer';

interface IdleTimerProps {
  pageRef: (ref: IdleTimer) => IdleTimer;
  timedOutHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

const IdleTimerComponent: FC<IdleTimerProps> = ({ pageRef, timedOutHandler }) => {
  const timeOutDuration = 1000 * 60 * 210; // 3 hours 30 minutes
  const onIdleHandler = (): void => {
    timedOutHandler(true);
  };

  return <IdleTimer ref={pageRef} timeout={timeOutDuration} onIdle={onIdleHandler} />;
};

export default IdleTimerComponent;
