import { microservices } from "./microservices";

/**
 * Abstracts away the need to know at call site in which service each route lives on
 */
const routes = {
  health: {
    edge: microservices.edge + "/health",
    contacts: microservices.contacts + "/health",
    sync: microservices.sync + "/health",
    contactsNest: microservices.contactsNest + "/health",
    auth: microservices.auth + "/health"
  },
  get: {
    v1: {
      contacts: microservices.contacts + "/v1/contacts",
    },
    twilio: {
      token: microservices.edge + "/twilio/token", //@deprecated
      tokenNest: microservices.edgeNest + "/twilio/token",
      getCurrentNumber: microservices.edgeNest + "/twilio/current/number",
      getNumberOfParticipants: microservices.edgeNest + "/twilio/participants/"
    },
    rabbit: {
      cosume: microservices.contacts + "/sqs/consume", //@deprecated
      consumeNest: microservices.contactsNest + "/rabbit/consume",
    },
    users: {
      user: microservices.edge + "/user", // @deprecated
      userNest: microservices.edgeNest + "/users",
      allActiveSdrs: microservices.edge + "/user/active/sdrs", // @deprecated
      allActiveSdrsNest: microservices.edgeNest + "/users/active/sdrs",
      /** @deprecated use {@link getForManagementNest} instead.*/
      getForManagement: microservices.edge + "/user/getForManagement",
      getForManagementNest: microservices.edgeNest + "/users/getForManagement",
      /** @deprecated use {@link getUserInfoNest} instead.*/
      getUserInfo: microservices.edge + "/user/getInfo",
      getUserInfoNest: microservices.edgeNest + "/users/getInfo",
      /** @deprecated use {@link getAgentValidationsNest} instead.*/
      getAgentValidations: microservices.edge + "/user/agentValidations",
      getAgentValidationsNest:
        microservices.edgeNest + "/users/agent-validations",
    },
    recordings: {
      getRecordingsBySdrId: microservices.contacts + "/recording/sdr/", // @deprecated
      getRecordingsBySdrIdNest: microservices.contactsNest + "/recording/sdr/",
      getRecordingByCallId: microservices.contactsNest + "/recording/callId",
      getRecordingById: microservices.contactsNest + "/recording",
    },
    sdrLogs: {
      sdrLog: microservices.contacts + "/sdr/log/",
    },
    callLogs: {
      callLog: microservices.contacts + "/call/log/", // @deprecated
      callLogNest: microservices.contactsNest + "/call-logs/",
      callsBySession: microservices.contactsNest + "/call-logs/calls-by-session/"
    },
    organizations: {
      organization: microservices.edge + "/organization", // @deprecated
      activeOrganizations: microservices.edge + "/organization/active", // @deprecated
      getForManagement: microservices.edge + "/organization/getForManagement", // @deprecated
      organizationNest: microservices.edgeNest + "/organization/all/inactive",
      activeOrganizationsNest: microservices.edgeNest + "/organization",
      getForManagementNest:
        microservices.edgeNest + "/organization/page/management",
      getById: microservices.edgeNest + "/organization/info",
    },
    contacts: {
      contactListsNest: microservices.contactsNest + "/contact/lists/orgId",
      byContactListIdNest: microservices.contactsNest + "/contact/lists",
      /** @deprecated use {@link contactListNest} instead.*/
      contactsNest: microservices.contactsNest + "/contacts/contactList",
      contactList: microservices.contacts + "/contact/lists", // @deprecated
      contactListNest: microservices.contactsNest + "/contact/lists",
      contactListByOrgId: microservices.contacts + "/contact/lists/orgId", // unused ?
      contacts: microservices.contacts + "/contacts", //deprecated
      byContactIdNest: microservices.contactsNest + "/contacts",
      byContactId: microservices.contacts + "/contacts/byId", //@deprecated
      byCallSessionIds: microservices.contactsNest + "/contacts/callsession-ids",
    },
    script: {
      scriptList: microservices.contacts + "/script",
      scriptListNestByOrgId:
        microservices.contactsNest + "/scripts/organizationId",
    },
    voicemail: {
      voicemailList: microservices.contacts + "/recording/sdr", // @deprecated
      voicemailListNest: microservices.contactsNest + "/recording/sdr",
      byCallId: microservices.contacts + "/recording/callId", // @deprecated
      byVoiceMailId: microservices.contacts + "/recording/", // @deprecated
    },
    callSession: {
      getById: microservices.contacts + "/call/session", //@deprecated
      getByIdNest: microservices.contactsNest + "/call-sessions/callSessionId",
      getByStatusAndSdrId:
        microservices.contacts + "/call/session/getByStatusAndSdrId",
      getByStatusAndAgentId:
        microservices.contacts + "/call/session/getByStatusAndAgentId", // @deprecated
      getByStatusAndAgentIdNest:
        microservices.contactsNest + "/call-sessions/getByStatusAndAgentId",
      getByMultipleStatusAndSdrId:
        microservices.contacts + "/call/session/getByMultipleStatusAndSdrId", // @deprecated
      getByMultipleStatusAndSdrIdNest:
        microservices.contactsNest +
        "/call-sessions/getByMultipleStatusAndSdrId",
      getBySdrId: microservices.contacts + "/call/session/sdrId",
      getBySdrIdNest: microservices.contactsNest + "/call-sessions/sdrId",
      getReport: microservices.contacts + "/call/session/getReport", //@deprecated
      getReportNest: microservices.contactsNest + "/call-sessions/getReport",
      getDialingStatus:
        microservices.contacts + "/call/session/getDialingStatus", //@deprecated
      getDialingStatusNest:
        microservices.contactsNest + "/call-sessions/getDialingStatus",
      getPreview: microservices.contacts + "/call/session/preview", //@deprecated
      getPreviewNest: microservices.contactsNest + "/call-sessions/preview",
      getCallSessionInfo:
        microservices.contactsNest + "/call-sessions/callSessionId",
      getActiveCallSessions: microservices.contactsNest + "/call-sessions/activeCallSessions"
    },
    metrics: {
      getByCallSessionId: microservices.contacts + "/metric/getByCallSessionId",
      getDynamicStats: microservices.contacts + "/metric/getDynamicStats",
      /** @deprecated use {@link buildMetricsNest} instead.*/
      buildMetrics: microservices.contacts + "/metric/buildMetrics",
      buildMetricsNest: microservices.contactsNest + "/metrics/build-metrics",
      /** @deprecated use {@link buildDispositionMetricsNest} instead.*/
      buildDispositionMetrics:
        microservices.contacts + "/metric/buildDispositionMetrics",
      buildDispositionMetricsNest:
        microservices.contactsNest + "/metrics/build-disposition-metrics",
    },
    dashboard: {
      getDashboardData: microservices.contacts + "/dashboard/getDashboardData", // @deprecated
      getDashboardNest: microservices.contactsNest + "/dashboard/sdrId",
      getSdrIdsByNameOrOrganization:
        microservices.edge + "/dashboard/searchDataByName", // @deprecated
      getSdrIdsByNameOrOrganizationNest:
        microservices.edgeNest + "/dashboard/searchDataByName",
      getSessionDetails: microservices.contactsNest + "/dashboard/session-details",
      recordings: `${microservices.contactsNest}/dashboard/recordings`
    },
    outreach: {
      getLinkedAccounts: microservices.sync + "/outreach-configs/config",
      getSequences: microservices.sync + "/outreach-configs/sequences",
      getSequenceSteps: microservices.sync + "/outreach-configs/sequence-steps",
      getProspects: microservices.sync + "/outreach-configs/prospects",
      getMappingsByOrgId: microservices.sync + "/outreach-mappings",
      getCustomDispositions:
        microservices.sync + "/outreach-mappings/dispositions",
    },
    hubspot: {
      getLinkedAccounts: microservices.sync + "/hubspot-configs/config",
      getLists: microservices.sync + "/hubspot-configs/contact-lists",
      getContacts:
        microservices.sync + "/hubspot-configs/contact-lists/contacts",
      getMappingsByOrgId: microservices.sync + "/hubspot-mappings",
      getCallDispositions:
        microservices.sync + "/hubspot-mappings/dispositions",
    },
    customHubspot: {
      getCustomConfiguration:
        microservices.sync + "/hubspot-configs-custom/config",
      getLinkedAccounts:
        microservices.sync + "/hubspot-configs-custom/accounts",
      getLists: microservices.sync + "/hubspot-configs-custom/contact-lists",
      getContacts:
        microservices.sync + "/hubspot-configs-custom/contact-lists/contacts",
    },
    featureToggle: {
      getFeaturesFlagsForOrg: microservices.sync + "/feature-flags",
    },
    numbers: {
      getForManagement: microservices.edgeNest + "/numbers",
    },
    callerId: {
      getNumbers: microservices.edgeNest + "/numbers/user",
    },
    auth: {
      getMe: microservices.auth + "/user/me",
      refreshSession: microservices.auth + "/user/refresh",
      logout: microservices.auth + "/user/logout",
    },
    logger:{
      token: microservices.edgeNest + "/auth/logger-token"
    }
  },
  post: {
    twilio: {
      sdrConference: microservices.edge + "/twilio",
      sdrConferenceNest: microservices.edgeNest + "/twilio",
    },
    v1: {
      login: microservices.edge + "/v1/login/", // @deprecated
      logout: microservices.edge + "/v1/login/logout", // @deprecated
    },
    auth: {
      login: microservices.edgeNest + "/auth/login",
      logout: microservices.edgeNest + "/auth/logout",
      refresh: microservices.edgeNest + "/auth/refresh",
      validate: microservices.edgeNest + "/auth/validate",
      authLogin: microservices.auth + "/user/login",
      resetPassword: microservices.auth + "/user/reset_pass_auth",
      resetPasswordAdmin: microservices.auth + "/user/admin_reset_pass"
    },
    contact: {
      contactsListNest: microservices.contactsNest + "/contact/lists",
      contactsNest: microservices.contactsNest + "/contacts",
      contactsList: microservices.contacts + "/contact/lists/", // @deprecated
      contacts: microservices.contacts + "/contacts", // @deprecated
    },
    recordings: {
      createRecording: microservices.contacts + "/recording/",
      playRecording: microservices.edge + "/twilio/", // @deprecated
      playRecordingNest: microservices.edgeNest + "/twilio/",
    },
    users: {
      user: microservices.edge + "/user", // @deprecated
      userNest: microservices.edgeNest + "/users",
      password: {
        /** @deprecated use {@link recoverNest} instead.*/
        recover: microservices.edge + "/password/recover",
        recoverNest: microservices.edgeNest + "/auth/recover",
        /** @deprecated In favor of new nest microservice {@link changeNest} instead */
        change: microservices.edge + "/password/update",
        sendVerificationCode:
          microservices.edgeNest + "/auth/send-verification-code",
        validateVerificationCode:
          microservices.edgeNest + "/auth/validate-verification-code",
      },
      uploadPicture: microservices.edgeNest + "/uploader/userProfilePic",
    },
    scripts: {
      script: microservices.contacts + "/script", //@deprecated
      scriptNest: microservices.contactsNest + "/scripts",
    },
    callSession: {
      create: microservices.contacts + "/call/session/", //@deprecated
      createNest: microservices.contactsNest + "/call-sessions",
      /** @deprecated In favor of new nest microservice {@link hangupNest} */
      hangup: microservices.contacts + "/call/session/hangup",
      /** @deprecated In favor of new nest microservice {@link answerNest} instead */
      answer: microservices.contacts + "/call/session/answer",
      answerNest: microservices.contactsNest + "/call-sessions/answer",
    },
    sqs: {
      load: microservices.contacts + "/sqs/load",
    },
    organizations: {
      /** @deprecated In favor of new nest microservice {@link organizationNest} instead */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    session: {
      refresh: microservices.edge + "/session/refresh", // deprecated
      validate: microservices.edge + "/session/validate", // deprecated
    },
    outreach: {
      oAuthLogin: microservices.sync + "/outreach-configs/login",
      updateJob: microservices.sync + "/outreach-jobs",
      createCustomDisposition: microservices.sync + "/outreach-mappings",
      updateCustomDisposition:
        microservices.sync + "/outreach-mappings/dispositions/update",
    },
    hubspot: {
      oAuthLogin: microservices.sync + "/hubspot-configs/login",
      updateJob: microservices.sync + "/hubspot-jobs",
      saveMappings: microservices.sync + "/hubspot-mappings",
    },
    customHubspot: {
      saveConfig: microservices.sync + "/hubspot-configs-customs",
      oAuthLogin: microservices.sync + "/hubspot-configs-custom/login",
      updateJob: microservices.sync + "/hubspot-jobs-custom",
    },
    numbers: {
      create: microservices.edgeNest + "/numbers",
    },
    callerId: {
      createNumber: microservices.edgeNest + "/twilio/caller",
      validate: microservices.edgeNest + "/twilio/validate",
    },
  },
  put: {
    callLog: {
      /** @deprecated In favor of new nest microservice {@link updateCallLog} */
      update: microservices.contacts + "/call/log",
      updateCallLog: microservices.contactsNest + "/call-logs",
    },
    callSession: {
      update: microservices.contacts + "/call/session", //@deprecated
      updateNest: microservices.contactsNest + "/call-sessions",
      hangupNest: microservices.contactsNest + "/call-sessions/hangup",
    },
    contacts: {
      update: `${microservices.contactsNest}/contacts`,
      updateNotes: microservices.contactsNest + "/contacts/notes",
    },
    recordings: { updateRecording: microservices.contactsNest + "/recording" },
    users: {
      /** @deprecated In favor of new nest microservice {@link userNest} instead */
      user: microservices.edge + "/user",
      userNest: microservices.edgeNest + "/users",
      password: {
        changeNest: microservices.edgeNest + "/auth/update",
        resetNest: microservices.edgeNest + "/auth/reset",
      },
    },
    organizations: {
      /** @deprecated In favor of new nest microservice {@link organizationNest} instead */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    numbers: {
      updateNumber: microservices.edgeNest + "/numbers",
      enableNumberPool: microservices.edgeNest + "/numbers/enable",
    },
    featureFlag: {
      update: microservices.sync + "/feature-flags",
    },
  },
  delete: {
    callSession: {
      agentsBySessionId:  microservices.contactsNest + "/call-sessions/agents/"
    },
    twilio: {
      /** @deprecated In favor of new nest microservice {@link deleteSdrLogNest} instead */
      deleteSdrLog: microservices.contacts + "/sdr/log/",
      deleteSdrLogNest: microservices.contactsNest + "/sdr-logs",
      deleteUserNumber: microservices.edgeNest + "/twilio/user",
    },
    script: {
      deleteScript: microservices.contacts + "/script", // @deprecated
      deleteScriptNest: microservices.contactsNest + "/scripts",
      editScriptNest: microservices.contactsNest + "/scripts",
    },
    voicemail: {
      deleteVoicemail: microservices.contacts + "/recording", // @deprecated
      deleteVoicemailNest: microservices.contactsNest + "/recording",
    },
    contacts: {
      deleteContactList: microservices.contacts + "/contact/lists", // @deprecated
      deleteContactListNest: microservices.contactsNest + "/contact/lists",
      deleteContact: microservices.contacts + "/contacts", // @deprecated
      deleteContactNest: microservices.contactsNest + "/contacts",
    },
    users: {
      user: microservices.edge + "/user", //@deprecated
      userNest: microservices.edgeNest + "/users",
    },
    organizations: {
      /** @deprecated use {@link organizationNest } instead. */
      organization: microservices.edge + "/organization",
      organizationNest: microservices.edgeNest + "/organization",
    },
    outreach: {
      deleteAccount: microservices.sync + "/outreach-configs/config",
      deleteCustomDisposition:
        microservices.sync + "/outreach-mappings/dispositions",
    },
    hubspot: {
      deleteAccount: microservices.sync + "/hubspot-configs/config",
    },
    numbers: {
      deleteNumber: microservices.edgeNest + "/numbers",
    },
    customHubspot: {
      deleteAccount: microservices.sync + "/hubspot-configs-custom/config",
    },
  },
  patch: {
    outreach: {
      updateMappings: microservices.sync + "/outreach-mappings",
    },
  },
};

export default routes;
