import routes from "../../constants/routes";
import axios from "axios";
import { CallSessionSuccessResponse } from "../../components/shared/CallSession";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

type Props = {
  sdrId: string | undefined;
};

type ResponseProps = {
  callSession?: CallSessionSuccessResponse | null;
  error?: { message: string, status: number };
};

const busySdrStatusOnCallSessionAsync = async ({
  sdrId,
}: Props): Promise<ResponseProps> => {
  try {
    const callSession = await axios.post<CallSessionSuccessResponse>(
      `${routes.post.callSession.answerNest}/${sdrId}`
    );
    return { callSession: callSession.data };
  } catch (e) {
    console.log(e, "busySdrStatusOnCallSessionAsync")
    const message = "Something happened answering the call session";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }
    return { error: { message, status: e.response.data.statusCode } };
  }
};

export default busySdrStatusOnCallSessionAsync;
