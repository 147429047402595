import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import { Box, Flex, Text, Button } from "../../core/ui";

export const StyledForm = styled.form`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const FieldContainer = styled(Box)`
  width: 220px;
  margin: 12px 0;

  input[type="text"],
  input[type="password"] {
    width: 100%;
    height: 36px;
    margin-top: 8px;
    padding: 0 10px;
    font-size: 14px;
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
  }

  input[type="password"] {
    font-size: 16px;
  }

  select {
    width: 220px;
    height: 36px;
    margin-top: 8px;
    font-size: 14px;
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
  }
`;

const LabelWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const StyledText = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const ErrorText = styled(Text)`
  margin-left: 8px;
  font-size: 12px;
  color: #f85149;
  text-align: left;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.4;
  }
`;

const Label = ({ label, meta }: { label: any; meta?: any }) => (
  <LabelWrapper>
    {typeof label === "object" && label}
    {typeof label === "string" && <label>{label}</label>}
    {meta && meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
  </LabelWrapper>
);

export const InputField = ({ input, meta, label, ...rest }) => (
  <FieldContainer {...rest}>
    <Label label={label} meta={meta} />
    <input
      {...input}
      data-qa-id={`input_${
        label?.props?.children
          ? label?.props?.children[0].props?.children.split(" ").join("_").toLowerCase()
          : label.split(" ").join("_").toLowerCase()
      }`}
    />
  </FieldContainer>
);

export const SelectField = ({
  input,
  meta,
  label,
  options,
  customOnChange,
  customRender,
}) => (
  <FieldContainer>
    <Label label={label} meta={meta} />
    <select
      {...input}
      data-qa-id={`selectInput_${label}`}
      onChange={(e) => {
        input.onChange(e);
        customOnChange && customOnChange(e);
      }}
    >
      <option value="" selected disabled>
        Select an option
      </option>
      {customRender
        ? customRender(options)
        : options.map(({ name, _id, orgName, id }) => (
            <option
              value={_id ? _id : id}
              key={_id ? _id : id}
              data-qa-id={`select_${name.split(" ").join("_")}`}
            >
              {orgName ? `${name} - ${orgName}` : name}
            </option>
          ))}
    </select>
  </FieldContainer>
);

export const CheckboxField = (props) => {
  const { input, label, customOnChange, visible } = props;
  return (
    <Flex>
      <input
        data-qa-id={`checkboxUserType_${label}`}
        {...input}
        onChange={(e) => {
          input.onChange(e);
          customOnChange && customOnChange(e);
          input.checked === true
            ? visible && visible(true)
            : visible && visible(false);
        }}
      />
      <StyledText>{label}</StyledText>
    </Flex>
  );
};

export const FieldsGroup = ({ groupName, label, children }) => {
  const labelWithErrors = (
    <>
      <label>{label}</label>
      <Error name={groupName} />
    </>
  );

  return (
    <FieldContainer as="fieldset">
      <Label label={labelWithErrors}></Label>
      {children}
    </FieldContainer>
  );
};

export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <ErrorText>{error}</ErrorText> : null
    }
  />
);
