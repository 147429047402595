import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { Box, BoxProps } from "../../core/ui";
import { TransparentButton } from "./";
import CloseIcon from "../../assets/icons/close_blue.svg";

const Container = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
`;

const translationTop = keyframes`
  0% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

const Wrapper = styled(Box)<{
  backgroundColor?: string;
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 85vh;
  background: ${({ theme, backgroundColor }) =>
    !backgroundColor && theme.colors.white};
  border-radius: 25px;
  box-shadow: rgb(0 0 0 / 20%) 0 0 25px 0;
  overflow-x: hidden;
  overflow-y: auto;
  animation: 0.5s ease ${translationTop};
  animation-fill-mode: forwards;
`;

const StyledTransparentButton = styled(TransparentButton)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  border-radius: 0 25px 0 0;
  z-index: 1;
  cursor: pointer;
  img {
    width: 15px;
  }
`;

const ChildrenWrapper = styled(Box)``;

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  closeIconPath?: string;
} & BoxProps;

const GenericModal = ({
  children,
  isOpen,
  onClose,
  closeIconPath,
  ...rest
}: Props): JSX.Element => {
  return (
    <>
      {isOpen && (
        <Container>
          <Wrapper {...rest}>
            <StyledTransparentButton id={'closeModal'} onClick={onClose}>
              <img src={closeIconPath} alt="close icon" />
            </StyledTransparentButton>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </Wrapper>
        </Container>
      )}
    </>
  );
};
GenericModal.defaultProps = {
  closeIconPath: CloseIcon,
};

export default GenericModal;
