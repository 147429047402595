import { LayoutMetricsUseCase } from '@usecase/layout/LayoutMetricsUseCase';
import { useOutletContext } from 'react-router-dom';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import { CurrentCallSessionMetrics } from '@domain/entities/currentCallSessionMetrics';
import { useEffect, useState } from 'react';

interface UIStatsViewmodelProps {
  uiStats: CurrentCallSessionMetrics;
}
export function UIStatsViewmodel(): UIStatsViewmodelProps {
  const { userSession } = useOutletContext<OutletContextProps>();
  const { getByMultipleStatusAndSdrId, getBuiltMetrics, getContactsByContactListId } = LayoutMetricsUseCase();
  const [uiStats, setUIStats] = useState<CurrentCallSessionMetrics>({
    called: 0,
    transferred: 0,
    voicemail: 0,
    totalContacts: 0,
  });

  const buildCurrentSessionLayoutMetrics = async (): Promise<CurrentCallSessionMetrics> => {
    const currentSession = await getByMultipleStatusAndSdrId(['started', 'paused'], userSession.userId);

    if (currentSession.data === undefined) {
      throw new Error(currentSession.error?.message);
    }

    const { id: sessionId, contactList } = currentSession.data.callSession;

    const list = await getContactsByContactListId(contactList[0].contactListId);
    if (list.data === undefined) {
      throw new Error(list.error?.message);
    }

    const { length } = list.data;

    const builtMetrics = await getBuiltMetrics(sessionId);

    if (builtMetrics.data === undefined) {
      throw new Error(builtMetrics.error?.message);
    }

    const { called, transferred, voicemail } = builtMetrics.data;

    return new CurrentCallSessionMetrics(called, transferred, voicemail, length);
  };

  useEffect(() => {
    void buildCurrentSessionLayoutMetrics().then((stats) => {
      setUIStats(stats);
    });
  }, []);

  return { uiStats };
}
