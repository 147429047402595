import { resetPasswordAsync } from '@datasource/auth/password/resetPassword';
import { type ResetPasswordAdminDto, type ResetPasswordDto } from '@datasource/auth/password/dto/resetPasswordDto';
import type { ApiResponse } from '@/src/types/ApiResponse';
import { resetPasswordAdminAsync } from '@datasource/auth/password/resetPasswordAdmin';

interface PasswordRepositoryProps {
  resetPassword: (body: ResetPasswordDto, token: string) => Promise<ApiResponse<Record<string, string>>>;
  resetPasswordAdmin: (body: ResetPasswordAdminDto) => Promise<ApiResponse<Record<string, string>>>;
}

export function PasswordRepository(): PasswordRepositoryProps {
  return {
    async resetPassword(body, token): Promise<ApiResponse<Record<string, string>>> {
      const { data, error, success } = await resetPasswordAsync(body, token);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
    async resetPasswordAdmin(body): Promise<ApiResponse<Record<string, string>>> {
      const { data, error, success } = await resetPasswordAdminAsync(body);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
