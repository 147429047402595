import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';
import { type SdrResponse } from '@datasource/edge/users/entities/user.entity';

export class DashboardData {
  id!: string;
  shortId!: string;
  sdrId!: string;
  startAt!: { $date: Date };
  updatedAt!: Date;
  listNames!: string;
  type!: string;
  totalContacts!: number;
  organizationId!: string;
}

export class DashboardResponse {
  data!: DashboardData[];
  pageNumber!: number;
  totalPages!: number;
  totalCount!: number;
}

export class DashboardSession {
  sdrData: SdrResponse;
  organizationData: ActiveOrganizations;
  sessionData: DashboardData;

  constructor(sdrData: SdrResponse, organizationData: ActiveOrganizations, sessionData: DashboardData) {
    this.sdrData = sdrData;
    this.organizationData = organizationData;
    this.sessionData = sessionData;
  }
}
export class PaginatedDashboardResponse {
  data!: DashboardSession[];
  pageNumber!: number;
  totalPages!: number;
  totalCount!: number;
}
