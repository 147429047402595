import { type ApiResponse } from '@/src/types/ApiResponse';
import { type ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import routes from '@/src/constants/routes';
import axios from 'axios';

export async function getContactByIdAsync(contactId: string): Promise<ApiResponse<ContactInfo>> {
  try {
    const contact: ApiResponse<ContactInfo> = await axios.get(`${routes.get.contacts.byContactIdNest}/${contactId}`);

    return { data: contact.data, success: true };
  } catch {
    return { error: { message: 'Could not find contact with id: '.concat(contactId) } };
  }
}
