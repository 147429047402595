import { type ResetPasswordDto } from '@datasource/auth/password/dto/resetPasswordDto';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';

export async function resetPasswordAsync(
  body: ResetPasswordDto,
  token: string,
): Promise<ApiResponse<Record<string, string>>> {
  try {
    const postConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    };

    const updatedPass = await axios.post(routes.post.auth.resetPassword, body, postConfig);
    return { success: true, data: updatedPass.data as Record<string, string> };
  } catch {
    return { error: { message: 'Cannot reset password' } };
  }
}
