import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';
import { type TokenObject, type TokenRefreshResponse } from '@datasource/edge/auth/dto/refreshToken.dto';

export async function refreshTokenEdgeAsync(token: TokenObject): Promise<ApiResponse<TokenRefreshResponse>> {
  try {
    const logoutResponse: ApiResponse<TokenRefreshResponse> = await axios.post(routes.post.auth.refresh, token);
    return { data: logoutResponse.data, success: true };
  } catch {
    return { error: { message: 'Error while logging out' }, success: false };
  }
}
export { TokenRefreshResponse };
