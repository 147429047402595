import type React from 'react';
import { type ChangeEvent, useContext, useRef, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { type ResetPasswordDto } from '@datasource/auth/password/dto/resetPasswordDto';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { AuthUseCase } from '@usecase/auth/AuthUseCase';
import { type LoginDto } from '@datasource/auth/login/dto/login.dto';
import axios from 'axios';
import routes from '@/src/constants/routes';
import { type UpdatePasswordDto } from '@datasource/edge/login/dto/edgeUpdatePassword.dto';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type StatusResponse } from '@/src/types';
import { toast } from '@components/ui/use-toast';

interface ChangePasswordViewModelProps {
  handleNewPassword: (e: ChangeEvent<HTMLInputElement>) => void;
  handleMatchConfirmPassword: (e: ChangeEvent<HTMLInputElement>) => void;
  handleInputCurrentPassword: (e: ChangeEvent<HTMLInputElement>) => void;
  feedback: string;
  passwordMatch: boolean;
  newPassword: React.MutableRefObject<string | undefined>;
  confirmPassword: React.MutableRefObject<string | undefined>;
  passwordMatchMessage: string;
  currentPasswordInserted: boolean;
  saveNewPassword: () => void;
  isStrongPassword: boolean;
  validatePasswordStrength: (password: string) => void;
}

export function ChangePasswordViewModel(): ChangePasswordViewModelProps {
  const [feedback, setFeedback] = useState('');
  const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [currentPasswordInserted, setInserted] = useState(false);
  const [isStrongPassword, setStrongPassword] = useState(false);
  const currentPassword = useRef<string>();
  const newPassword = useRef<string>();
  const confirmPassword = useRef<string>();
  const { currentUser: userSession } = useContext(UserProviderContext);

  const { resetPassword, login } = AuthUseCase();

  function handleNewPassword(e: ChangeEvent<HTMLInputElement>): void {
    newPassword.current = e.target.value;
    const confirmInput = document.getElementById('confirmNewPasswordInput');
    if (confirmInput !== null) {
      confirmInput.removeAttribute('value');
    }
    const result = zxcvbn(newPassword.current);
    const feedbackMessage = result.feedback.suggestions.join(', ');
    validatePasswordStrength(newPassword.current);
    setFeedback(feedbackMessage);
    setPasswordMatch(false);
  }

  function validatePasswordStrength(password: string): void {
    const score = zxcvbn(password).score;
    if (score < 3) {
      setStrongPassword(false);
    } else {
      setStrongPassword(true);
    }
  }

  function handleMatchConfirmPassword(e: ChangeEvent<HTMLInputElement>): void {
    confirmPassword.current = e.target.value;
    if (newPassword.current !== confirmPassword.current) {
      setPasswordMatchMessage('Passwords do not match');
      if (passwordMatch) {
        setPasswordMatch(false);
      }
    }
    if (newPassword.current === confirmPassword.current && isStrongPassword) {
      setPasswordMatchMessage('');
      setPasswordMatch(true);
    }
  }

  function handleInputCurrentPassword(e: ChangeEvent<HTMLInputElement>): void {
    currentPassword.current = e.target.value;
    if (currentPassword.current !== '') {
      setInserted(true);
    } else {
      setInserted(false);
    }
  }

  function saveNewPassword(): void {
    if (
      newPassword.current !== undefined &&
      confirmPassword.current !== undefined &&
      currentPassword.current !== undefined
    ) {
      const loginBody: LoginDto = {
        email: userSession.email,
        password: currentPassword.current,
      };
      const edgeBody: UpdatePasswordDto = {
        token: window.localStorage.getItem('target') ?? '',
        password: newPassword.current,
        currentPassword: currentPassword.current,
      };

      const body: ResetPasswordDto = {
        email: userSession.email,
        current_pass: currentPassword.current,
        new_pass_first: newPassword.current,
        new_pass_second: confirmPassword.current,
      };

      void login(loginBody).then((loginResponse) => {
        if (loginResponse.data !== undefined) {
          void axios
            .put(routes.put.users.password.changeNest, edgeBody)
            .then((response: ApiResponse<StatusResponse>) => {
              if (response.data !== undefined) {
                if (loginResponse.data !== undefined) {
                  void resetPassword(body, loginResponse.data.access_token).then(
                    (response: ApiResponse<Record<string, string>>) => {
                      if (response.data !== undefined) {
                        toast({
                          title: 'Success',
                          description: 'Your password was successfully updated',
                          variant: 'default',
                        });
                      } else {
                        throw new Error('Could not reset password');
                      }
                    },
                  );
                } else {
                  throw new Error('Could not get the token');
                }
              }
            });
        } else {
          toast({
            title: 'Error!',
            description: 'Your password could not be updated',
            variant: 'destructive',
          });
          throw new Error('Could not update password');
        }
      });
    }
  }

  return {
    handleNewPassword,
    handleMatchConfirmPassword,
    saveNewPassword,
    handleInputCurrentPassword,
    validatePasswordStrength,
    feedback,
    passwordMatch,
    passwordMatchMessage,
    currentPasswordInserted,
    isStrongPassword,
    newPassword,
    confirmPassword,
  };
}
