export class CurrentCallSessionMetrics {
  called: number;
  transferred: number;
  voicemail: number;
  totalContacts: number;

  constructor(called: number, transferred: number, voicemail: number, totalContacts: number) {
    this.called = called;
    this.transferred = transferred;
    this.voicemail = voicemail;
    this.totalContacts = totalContacts;
  }
}
