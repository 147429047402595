import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SafeUserDto } from '@datasource/edge/users/dto/safeUser.dto';
import uploadImageAsync from '../../../DataSource/edge/users/updateUserAccount';
import { type UploadImageDto } from '@datasource/edge/users/dto/uploadImage.dto';
import getUserAsync from '../../../DataSource/edge/users/getUserAsync';
import { type UpdateUserDto } from '@/src-v2/Data/DataSource/edge/users/dto/updateUserDto';
import updateUserAsync from '@/src-v2/Data/DataSource/edge/users/updateUserAsync';
import getAllActiveSDRAsync from '@datasource/edge/users/getAllActiveSDR';
import { type SdrResponse } from '@datasource/edge/users/entities/user.entity';

interface UsersRepo {
  uploadProfilePicture: (userId: string, profilePicture: UploadImageDto) => Promise<ApiResponse<SafeUserDto>>;
  getUserAsync: (userId: string) => Promise<ApiResponse<SafeUserDto>>;
  updateUserAsync: (userData: UpdateUserDto) => Promise<ApiResponse<SafeUserDto>>;
  getAllActiveSdrs: (
    userId: string,
    userIds: string[] | null,
    organizationId: string | null,
  ) => Promise<ApiResponse<SdrResponse[]>>;
}

export function UsersRepository(): UsersRepo {
  return {
    async uploadProfilePicture(userId: string, profilePicture: UploadImageDto) {
      const { data, success, error } = await uploadImageAsync(userId, profilePicture);
      if (data === null || error !== null) {
        return { error, success };
      }
      return { data, success: true };
    },

    async getUserAsync(userId: string) {
      const { data, success, error } = await getUserAsync(userId);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async updateUserAsync(userData: UpdateUserDto) {
      const { data, success, error } = await updateUserAsync(userData);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async getAllActiveSdrs(userId: string, userIds: string[] | null, organizationId: string | null) {
      const { data, success, error } = await getAllActiveSDRAsync(userId, userIds, organizationId);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
  };
}
