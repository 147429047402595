import { type ApiResponse } from '@/src/types/ApiResponse';
import { type MultipleStatusAndSdrIdResponse } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import axios from 'axios';
import routes from '@/src/constants/routes';

export async function getByMultipleStatusAndSdrIdAsync(
  status: string[],
  sdrId: string,
): Promise<ApiResponse<MultipleStatusAndSdrIdResponse>> {
  try {
    const params = status.map((param: string) => `status=${param}`).join('&');
    const response = await axios.get<MultipleStatusAndSdrIdResponse>(
      `${routes.get.callSession.getByMultipleStatusAndSdrIdNest}/${sdrId}?${params}`,
    );
    return { data: response.data, success: true };
  } catch {
    return { error: { message: 'Could not retrieve the Call Session by statuses and SdrId' } };
  }
}
