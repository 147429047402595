import { type ApiResponse } from '@/src/types/ApiResponse';
import routes from '@/src/constants/routes';
import { type BuiltMetric } from '@datasource/contacts-nest/metrics/entities/metrics.entity';
import axios from 'axios';

const getBuiltMetricsAsync = async (callSessionId: string): Promise<ApiResponse<BuiltMetric>> => {
  try {
    const metrics: ApiResponse<BuiltMetric> = await axios.get(
      `${routes.get.metrics.buildMetricsNest}/${callSessionId}`,
    );
    return { data: metrics.data, success: true };
  } catch {
    return {
      error: {
        message: 'Error getting build metrics',
      },
    };
  }
};

export default getBuiltMetricsAsync;
