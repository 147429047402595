import React, {createContext, ReactElement, useContext, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import useAuthApi from "../../api/Auth/AuthAPI";
import {useLogger} from "./LoggerProvider";
import {UserProviderContext} from "./UserProvider";
import { useCookies } from "react-cookie";
import {logoutEdgeAsync} from "../../../src-v2/Data/DataSource/edge/auth/logoutEdgeAsync";
import {ApiResponse} from "../../types/ApiResponse";
import {AuthenticationResponse} from "../../../src-v2/Data/DataSource/auth/login/dto/login.dto";
import {PublicRoutes} from "../../constants/routesConnect";

const window = globalThis as any;

interface AuthContextProps {
  startSession: (credentials: { email: string; password: string }) => void;
  endSession: () => void;
}

const AuthProviderContext = createContext<AuthContextProps | undefined>(undefined);

interface Props {
  children: ReactElement;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [tabId, setTabId] = useState<any>(uuidv4());
  const [timestamp, setTimestamp] = useState<number>(() => new Date().getTime());
  const [sessionIdentifiers, setSessionIdentifiers] =
      useState<{ authToken: string | null, tabUuid: string | null }>({authToken: null, tabUuid: null});
  const [cookie, setCookie] = useCookies(['tabUuid', 'access_token', 'refresh_token']);
  const lastRefreshTimeRef = useRef<number>(0);
  const {login, logout, getMe, refresh} = useAuthApi();
  const {currentUser} = useContext(UserProviderContext);
  const {logger} = useLogger();
  const loggerRef = useRef(logger);


   /** Allows us to keep track of duplicate tabs.  */
  const authChannel = new BroadcastChannel('auth_channel')

  authChannel.onmessage = (event) => {

    /** It's divided by 100 to reduce the difference in miliseconds(hh:mm:ss:ms) of the tab timestamps*/
    if (event.data.type === 'check_open_tabs' && Math.floor(timestamp / 100) <  Math.floor(event.data.timestamp / 100)) {
      if (event.data.tabId !== tabId) {
        console.error('Session closed due to duplicate tab detected.');
        window.location.replace(`/${PublicRoutes.LOGIN}?e=2`);
      };
    };

  };

  /** Logs in a user using Auth Microservice and settles the tab UUID cookie and the session Identifiers: token and UUID.
   * @Params: Credentials: email and password.
   */
  const startSession = async (credentials: { email: string; password: string }) => {
    const {success, data}: ApiResponse<AuthenticationResponse> = await login(credentials);

    if (success && data) {
      setSessionIdentifiers({authToken: data.access_token, tabUuid: null});
    }
  };

  /** Logs out a user using both Edge and Auth Microservice and
   * Cleans the tab UUID cookie and the session Identifiers: token and UUID.
   * Cleans local storage to remove the Edge token.
   * Cleans the cookies that allow Auth Session to be active.
   */
  const endSession = async () => {
    await logoutEdgeAsync(window.localStorage.getItem('target'));

    loggerRef.current.info(`User has logged out: [id:${currentUser.userId}, email:${currentUser.email}`, {
      componentName: AuthProvider.name
    });

    window.localStorage.setItem('target', '');

    await logout();

    setSessionIdentifiers({tabUuid: '', authToken: ''})
      setCookie("access_token",'',  {path: '/'});
      setCookie("refresh_token",'', {path: '/'});
      setSessionIdentifiers({tabUuid: '', authToken: ''})
  };

  /** Triggers a session refresh (Auth):
   *  if the session cannot be refreshed means there is a duplication and triggers a logout,
   *  dropping the first logged-in user out.
   *  If the refresh is done, updates the cookies if necessary, and the session identification token state.
   *  The drop out (end session) action redirects the user to login?e=1, this param is read by Login page to display a toast to inform the user.
   */
  const refreshSession = async () => {

    const currentTime = new Date().getTime();

    /** It's validated to not allow more than one request in an interval of less than 5 seconds, to avoid making requests with expired tokens. */
    if (currentTime - lastRefreshTimeRef.current <= 5000) {
      return;
    };

    lastRefreshTimeRef.current = new Date().getTime();

    const {data} = await refresh();
   
    if (data === undefined) {
      console.error('Forbidden: Detected session duplication.');

      loggerRef.current.error(`The session was closed by another session opened in other location, user: ${currentUser.email}`, {
        componentName: AuthProvider.name
      });

      loggerRef.current.error(`User was dropped out, due to session duplication. User: ${currentUser.userId}`, {
        componentName: AuthProvider.name
      });

      await endSession();
      // This parameter is used to display a toast in the Login Page after dropping out.
      window.location.replace(`/${PublicRoutes.LOGIN}?e=1`);

    }

    if (data) {
      setSessionIdentifiers({tabUuid: null, authToken: data.access_token});
      setCookie("access_token", data.access_token);
      loggerRef.current.info(`The session was successfully refreshed for: ${currentUser.email}`, {
        componentName: AuthProvider.name,
        userId: currentUser.userId,
      });
    }
  };

  /** Triggers a recurring validation of the session while the App is in a route different to Login.
   * Tries to identify a user using Auth /user/me, if this validation fails,
   * it tries to refresh the token, if the token fails, then triggers a session end.
   */
  const checkSessionValidity = async () => {
    const sessionCheckInterval = setInterval(async () => {
      const queryParameters = window.location.pathname;
      if (queryParameters !== '/login') {

        authChannel.postMessage({ type: 'check_open_tabs', timestamp: timestamp, tabId });

        const {success} = await getMe();
        if (!success) {
          await refreshSession().then(()=>{
            return () => clearInterval(sessionCheckInterval);
          })
        }
      }

    }, 10 * 1000); // Check session every 10 seconds

    return () => clearInterval(sessionCheckInterval);
  };

  useEffect(() => {
    loggerRef.current = logger;
  }, [logger, currentUser]);

  useEffect(() => {
 
      checkSessionValidity().finally();

  }, []);

  const contextValue: AuthContextProps = {
    startSession,
    endSession,
  };

  return (
      <AuthProviderContext.Provider value={contextValue}>
        {children}
      </AuthProviderContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthProviderContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
