import React from 'react';
interface UIComponentProps {
  isSettings?: boolean;
  tabsIsEmpty?: boolean;
  children: React.ReactNode;
}
const UIContainerComponent: React.FC<UIComponentProps> = ({ isSettings = false, tabsIsEmpty = false, children }) => {
  return (
    <div
      className={`items-${(isSettings && !tabsIsEmpty ? 'start' : 'center').concat(
        ' grid-area-content z-9 relative flex-1 flex-row justify-center flex-wrap gap-4 p-4 bg-primary bg-gradient-to-l from-primary to-[#2DC4ED] md:gap-8 md:p-4',
      )}`}
    >
      {children}
    </div>
  );
};

export default UIContainerComponent;
