import React, { type FC } from 'react';
import { IconStarFilled } from '@tabler/icons-react';
import { UIStatsViewmodel } from '@views/Layout/Components/UIStats.viewmodel';

interface UIStatsProps {
  opened: boolean;
}

const UIStatsComponent: FC<UIStatsProps> = ({ opened }) => {
  const {
    uiStats: { called, transferred, voicemail, totalContacts },
  } = UIStatsViewmodel();
  const uiStatsData = [
    { name: 'Number of calls', number: called },
    { name: 'Number of voicemails', number: voicemail },
    { name: 'Number of transfers', number: transferred },
    { name: 'Number of total contacts', number: totalContacts },
  ];
  return (
    <div id="uiStats" className="flex flex-col border-solid my-5 w-full px-2">
      <div
        id="uiStatsHeader"
        className={`${
          !opened ? 'justify-center' : 'justify-start'
        } flex w-full bg-white rounded-t-lg items-center gap-2 py-1 px-2`}
      >
        <IconStarFilled size={14} color="hsl(var(--primary))" />
        {opened && <div className="text-primary font-bold text-sm">Current call session stats</div>}
      </div>

      {uiStatsData.map((stat, index) => {
        return (
          <div
            id={`uiStatsRow-${index}`}
            key={index}
            className={`flex p-1 border-white border-x border-b 
              ${index !== uiStatsData.length - 1 ? '' : 'rounded-b-lg'}
              ${!opened ? 'justify-center' : 'justify-between'}`}
          >
            {opened && <div className="text-white font-light text-left text-xs">{stat.name}</div>}
            <div className="text-white font-light text-center text-xs">{stat.number}</div>
          </div>
        );
      })}
    </div>
  );
};

export default UIStatsComponent;
