import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';

async function getAllOrganizationsAsync(): Promise<ApiResponse<ActiveOrganizations[]>> {
  try {
    const activeOrganizations: ApiResponse<ActiveOrganizations[]> = await axios.get(
      routes.get.organizations.organizationNest,
    );
    return { data: activeOrganizations.data, success: true };
  } catch {
    return { error: { message: 'Error getting organizations' }, success: false };
  }
}

export default getAllOrganizationsAsync;
