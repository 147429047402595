import { type AgentCallInfo } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios, { AxiosError } from 'axios';
import routes from '@/src/constants/routes';

const getAgentsCallInfo = async (sessionId: string): Promise<ApiResponse<AgentCallInfo[]>> => {
  try {
    const agentsCallInfo = await axios.get<AgentCallInfo[]>(`${routes.get.callLogs.callsBySession}${sessionId}`);

    return { data: agentsCallInfo.data, success: true };
  } catch (e) {
    let status: number | null = null;
    if (e instanceof AxiosError && e.response !== undefined) {
      status = e.response.status;
    }

    return {
      error: { message: status === 404 ? 'No calls were made for this session.' : 'Error getting call logs.' },
      success: false,
    };
  }
};

export default getAgentsCallInfo;
