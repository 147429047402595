import React, { type FC } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@components/ui/form';
import { Switch } from '@components/ui/switch';

import { type FeatureFlagsViewModelReturn } from '@views/Management/Components/Dialogs/FeatureFlags/FeatureFlags.viewmodel';

import { LoadingState } from '@/src/types/LoadingStates.type';
import { Skeleton } from '@/src-v2/Presentation/Common/Components/ui/skeleton';
import { ScrollArea } from '@/src-v2/Presentation/Common/Components/ui/scroll-area';

const { LOADING } = LoadingState;

const FeatureFlagsFormSchema = z.object({
  outreach: z.boolean(),
  userSettings: z.boolean(),
  accountSettings: z.boolean(),
  fourMativ: z.boolean(),
  hubspot: z.boolean(),
  hubspotCustom: z.boolean(),
  numbersPool: z.boolean(),
  callerId: z.boolean(),
  contactsFilter: z.boolean(),
  dashboardDetails: z.boolean(),
  callRecordings: z.boolean(),
});

export type FeatureFlagsForm = z.infer<typeof FeatureFlagsFormSchema>;

export const DEFAULT_FORM_VALUES: FeatureFlagsForm = {
  outreach: false,
  userSettings: false,
  accountSettings: true,
  fourMativ: false,
  hubspot: false,
  hubspotCustom: false,
  numbersPool: false,
  callerId: true,
  contactsFilter: false,
  dashboardDetails: false,
  callRecordings: false,
};

const FEATURES_FRIENDLY_PROPS = new Map<
  string,
  {
    friendlyName: string;
    description: string;
  }
>([
  ['outreach', { friendlyName: 'Outreach', description: 'Activates Outreach integration' }],
  ['fourMativ', { friendlyName: '4mativ', description: 'Activates 4mativ integration' }],
  ['hubspot', { friendlyName: 'Hubspot', description: 'Activates Hubspot integration' }],
  ['hubspotCustom', { friendlyName: 'Hubspot Custom', description: 'Activates Hubspot Custom integration' }],
  ['userSettings', { friendlyName: 'User Settings', description: 'Activates User Settings (will be deprecated soon)' }],
  ['accountSettings', { friendlyName: 'Account Settings', description: 'Activates User Account Settings v2 feature' }],
  ['numbersPool', { friendlyName: 'Numbers Pool', description: 'Activates Numbers Pool feature' }],
  ['callerId', { friendlyName: 'Caller ID', description: 'Activates Caller ID feature' }],
  ['contactsFilter', { friendlyName: 'Contacts Filter', description: 'Activates Contacts Filter feature' }],
  ['dashboardDetails', { friendlyName: 'Dashboard Details', description: 'Activates new Dashboard' }],
  [
    'callRecordings',
    { friendlyName: 'Call Recordings', description: 'Activates new Call Recordings for dashboard details' },
  ],
]);

const FeatureFlagsFormView: FC<{ viewModel: FeatureFlagsViewModelReturn; children?: React.ReactNode }> = ({
  viewModel,
  children,
}) => {
  const {
    viewState: { featureFlags, organizationId },
    viewLoadingState: { getFeatureFlags },
    updateFlags,
  } = viewModel;

  const form = useForm<FeatureFlagsForm>({
    resolver: zodResolver(FeatureFlagsFormSchema),
    values: featureFlags,
    mode: 'onChange',
  });

  function onSubmit(): void {
    void updateFlags(organizationId, form.getValues());
  }

  return getFeatureFlags === LOADING ? (
    <div className="space-y-4">
      {[1, 2, 3, 4, 5].map((key) => {
        return (
          <div className="flex items-center space-x-4" key={`${key}_skeleton`}>
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
            <Skeleton className="h-12 w-12 rounded-full" />
          </div>
        );
      })}
    </div>
  ) : (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <ScrollArea className="h-[75svh]">
          <div className="space-y-4">
            {Object.keys(featureFlags).map((key) => {
              return (
                <FormField
                  key={key}
                  data-qa-id={`form-field-${key}`}
                  control={form.control}
                  name={key as keyof FeatureFlagsForm}
                  render={({ field }) => (
                    <FormItem className="grid grid-flow-col-dense">
                      <div>
                        <FormLabel>{FEATURES_FRIENDLY_PROPS.get(key)?.friendlyName}</FormLabel>
                        <FormDescription>{FEATURES_FRIENDLY_PROPS.get(key)?.description}</FormDescription>
                      </div>
                      <FormControl className="ml-auto place-self-center">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          disabled={FEATURES_FRIENDLY_PROPS.get(key)?.friendlyName === 'Call Recordings'}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              );
            })}
          </div>
        </ScrollArea>
        {children}
      </form>
    </Form>
  );
};

export default FeatureFlagsFormView;
