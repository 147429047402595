import { type AuthenticationResponse, type LoginDto } from '@datasource/auth/login/dto/login.dto';
import type { ApiResponse } from '@/src/types/ApiResponse';
import { loginAuth } from '@datasource/auth/login/login';

interface LoginRepositoryProps {
  loginUser: (body: LoginDto) => Promise<ApiResponse<AuthenticationResponse>>;
}

export function LoginRepository(): LoginRepositoryProps {
  return {
    async loginUser(body: LoginDto): Promise<ApiResponse<AuthenticationResponse>> {
      const { data, error, success } = await loginAuth(body);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}
