import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';

async function getNumberOfParticipants(callId: string, sdrId: string): Promise<ApiResponse<number>> {
  try {
    const numberOfParticipants: ApiResponse<number> = await axios.get(
      `${routes.get.twilio.getNumberOfParticipants}${callId}/${sdrId}`,
    );

    return { data: numberOfParticipants.data, success: true };
  } catch {
    return { error: { message: 'Could not retrieve the number of participants' }, success: false };
  }
}

export default getNumberOfParticipants;
