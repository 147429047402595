import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { StatusCodes } from "http-status-codes";

interface GetSearchByFields {
  urlEndpoint: string;
  fields: string[];
  searchTerm: string;
}

export default async function getSearchByFields<T>({
  urlEndpoint,
  fields,
  searchTerm = "",
}: GetSearchByFields): Promise<ApiResponse<T>> {
  try {
    const querySign = urlEndpoint.includes("?") ? "" : "?";
    const params = fields.map((field) => `${field}=${searchTerm}`).join("&");
    const response = await axios.get<T>(`${urlEndpoint}${querySign}${params}`);

    return {
      success: true,
      data: response.data,
    };
  } catch (e: unknown) {
    if (
      axios.isAxiosError(e) &&
      e?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      console.log(e)
    }

    if (
      axios.isAxiosError(e) &&
      e?.response?.status === StatusCodes.NOT_FOUND
    ) {
      return {
        success: true,
        error: {
          message: `No results were found for ${searchTerm}.`,
        },
      };
    }

    return {
      success: false,
      error: {
        message:
          "An error occurred while retrieving data. If the issue persists, please contact customer support.",
      },
    };
  }
}
