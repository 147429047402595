import React, { type FC } from 'react';

export interface SettingsSectionProps {
  name: string;
  children: React.ReactNode;
}

const SettingsSection: FC<SettingsSectionProps> = ({ children, name }) => {
  return (
    <>
      <div className="flex w-full grow flex-col flex-wrap my-4 md:flex-row md:flex-nowrap pb-8">
        {/* <!-- fixed-width --> */}
        <div className="relative min-w-max flex-shrink grow-0">
          <div className="sticky top-0 h-full w-[160px]">
            <h4 className="text-base font-semibold text-clip pr-2">{name}</h4>
          </div>
        </div>
        <main role="main" className="min-w-[70%] w-full grow space-y-2">
          {children}
        </main>
        <div className="hidden lg:flex w-fixed w-full shrink grow-0">
          {/* <!-- fixed-width --> */}
          <div className="flex md:flex-col"></div>
        </div>
      </div>
    </>
  );
};

export default SettingsSection;
