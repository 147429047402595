import zxcvbn from 'zxcvbn';

export const isPhoneNumberValid = (number: string) => {
  const regex = new RegExp("^\\+[1-9]\\d{1,14}$");
  return regex.test(number);
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const required = (value) => (value ? undefined : "Required");

export const email = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? undefined
    : "Invalid email address";

export const password = (value) =>
  value &&
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)
    ? undefined
    : "Invalid password";

export const validatePassword = (value) =>
    value && zxcvbn(value).score > 3 ? undefined : zxcvbn(value).feedback.suggestions.join('');

export const minLength = (min) => (value) =>
  value && value.length < min ? `At least ${min} characters` : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `At most ${max} characters` : undefined;

export const titleKey = (title: string, index: number): string => {
  const name = title.replace(" ", "_").trim().toLowerCase();
  return `${name}_${index}`;
};
