import routes from "../../constants/routes";
import axios from "axios";
import { CallSessionAction, CallSessionSuccessResponse } from "../../components/shared/CallSession";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";


type dataProps = {
  status?: string;
  sdrId?: string;
  sdrStatus?: string;
  speech?: string;
  notes?: [string];
  action?: CallSessionAction;
};

type Props = {
  data: dataProps;
  id: string | undefined;
};

type ResponseProps = {
  callSession?: CallSessionSuccessResponse | null;
  error?: { message: string };
};

const updateCallSessionAsync = async ({
  data,
  id,
}: Props): Promise<ResponseProps> => {
  try {
    const callSession = await axios.put<CallSessionSuccessResponse>(
      routes.put.callSession.updateNest + `/${id}`,
      data
    );
    return { callSession: callSession.data };
  } catch (e) {
    const message = "Something went wrong updating the call session";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }
    return { error: { message } };
  }
};

export default updateCallSessionAsync;
