import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import { ArrowLeftIcon, RefreshCwIcon } from 'lucide-react';
import SettingsTitleDivider from '../../Components/SettingsTitleDivider.component';
import { Button } from '@/src-v2/Presentation/Common/Components/ui/button';
import useTimeAgo from '@/src/Presentation/Common/hooks/useTimeAgo.hook';
import { useNavigate, useParams } from 'react-router-dom';
import ActiveSessionsViewmodel from '../ActiveSessions.viewmodel';

const AgentsCallTable: React.FC = () => {
  const { agentsCallInfo, selectedSdrData, getAgentsCallInfo } = ActiveSessionsViewmodel();

  const { timeAgo, updateTimeAgoDate } = useTimeAgo();

  const { callSessionId } = useParams();

  const navigate = useNavigate();

  const reload = async (): Promise<void> => {
    if (callSessionId !== undefined) {
      await getAgentsCallInfo(callSessionId);
    }
    updateTimeAgoDate(new Date());
  };

  const handleReload = (): void => {
    void reload();
  };

  const handleBack = (): void => {
    navigate('/settings/active-sessions');
  };

  useEffect(() => {
    const fetchAgentsCallInfo = async (): Promise<void> => {
      if (callSessionId !== undefined) {
        await getAgentsCallInfo(callSessionId);
        updateTimeAgoDate(new Date());
      }
    };
    void fetchAgentsCallInfo();
  }, []);
  return (
    <>
      <SettingsTitleDivider
        title="Agents Call Statistics"
        description={`SDR: ${selectedSdrData.name} - ${selectedSdrData.sdrId}`}
        secondDescription={`Organization: ${selectedSdrData.orgName ?? ''} - ${selectedSdrData.orgId ?? ''}`}
        thirdDescription={`Session ID: ${callSessionId ?? ''}`}
      />

      <div className="flex justify-between items-center w-full px-4 mb-8 mt-4">
        <Button onClick={handleBack} className="btn flex items-center space-x-2">
          <ArrowLeftIcon className="h-5 w-5" />
          <span>Back to Active Sessions</span>
        </Button>

        <Button
          onClick={handleReload}
          className="flex items-center space-x-2 bg-transparent border-none text-blue-500 hover:text-blue-700"
        >
          {timeAgo !== undefined && <span>{timeAgo}</span>}
          <RefreshCwIcon className="h-5 w-5" />
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Agent</TableCell>
            <TableCell>Total Calls</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {agentsCallInfo?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="flex flex-col">
                  <span className="font-semibold">{item.agentName}</span>
                  <span className="text-xs text-gray-300">id: {item.agentId}</span>
                </div>
              </TableCell>
              <TableCell>{item.totalCalls}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AgentsCallTable;
