import React, { ChangeEvent, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import SideBar from '../SideBar/SideBar';
import { UserProviderContext } from '../app/UserProvider';
import IdleTimerContainer from '../session/InactivitySession';
import ExpiredSessionModal from '../session/ExpiredSessionModal';
import jwt_decode from 'jwt-decode';
import { refreshSessionToken } from '../../core/utils/sessionRefresher';
import { featuresStore } from '../app/FeaturesProvider';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { UIMain, UIHeader, UIBurgerButton, UIContainer, UIButton } from '../../core/ui/UIElements';
import { useAppContext } from '../../libs/contextLib';
import useScreenSize from '../../core/hooks/useScreenSize';
import {
  NavAvatarImage,
  UserAvatarImage,
} from '../../../src-v2/Presentation/Views/Settings/Account/Components/UserAvatarImage.component';
import { Label } from '../../../src-v2/Presentation/Common/Components/ui/label';
import { Input } from '../../../src-v2/Presentation/Common/Components/ui/input';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from '../../../src-v2/Presentation/Common/Components/ui/alert-dialog';
import { AlertDialogDescription, AlertDialogTitle } from '@radix-ui/react-alert-dialog';
import { showUIToast, UI_TOAST_TYPES } from '../../core/ui/UIToast';
import { SafeUserDto } from '../../../src-v2/Data/DataSource/edge/users/dto/safeUser.dto';
import { Timezone } from '../../../src-v2/Domain/enums/Users/timezone';
import { Button } from '../../../src-v2/Presentation/Common/Components/ui/button';
import { UsersUseCase } from '../../../src-v2/Domain/UseCase/users/UsersUseCase';
import getUserInfoAsync from '../../api/Users/getUserInfo';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '../../../src-v2/Presentation/Common/Components/ui/tooltip';
import {InfoCircledIcon} from '@radix-ui/react-icons';
import {useOutletContext} from "react-router-dom";
import useTimeAgo from '../../Presentation/Common/hooks/useTimeAgo.hook';
import { RefreshCwIcon } from 'lucide-react';
import { SessionDetail } from '../../types';
import getAgentValidations from '../../api/Users/getAgentValidations';
import getOrganizationInfo from "../../api/Organizations/getOrganizationInfo";
import { useAuth } from '../app/AuthProvider';


const { getUserAsync, updateUserData } = UsersUseCase();

const MINUTE_TO_EXPIRATION = 1000 * 60 * 90;

type Props = {
  sidebar?: boolean;
  user?: string;
  children: ReactNode;
  handleLogout?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
  callStatus?: string | undefined;
  sdrStatus?: string | undefined;
  isSettings?: boolean | undefined;
  tabsIsEmpty?: boolean | undefined;
};

type decoded = {
  exp: number;
};

interface NavImageView {
  showAvatarModal: boolean;
  actualPicture: string;
  hasImage: boolean;
}

const Layout = ({
  sidebar,
  children,
  handleLogout,
  intervals,
  setIntervals,
  callStatus,
  sdrStatus,
  isSettings,
  tabsIsEmpty,
}: Props): JSX.Element => {
  const { userSession: userTypeSdr, userTypeAgent } = useOutletContext() as any;
  const {clearCurrentUser, currentUser}  = useContext(UserProviderContext);
  const { isAuthenticated } = useAppContext();
  const { resetAllFeatures } = useContext(featuresStore);
  const defaultImageUri = 'tondril-dude.svg';
  const { uploadProfilePicture } = UsersUseCase();
  const screenSize = useScreenSize();
  const isSdr = userTypeSdr;
  const isAutoHideMenuAvailable: boolean = currentUser.isSuperAdmin || isSdr;
  const isAgent = userTypeAgent;
  const [menuStatus, setMenuStatus] = useState(isAutoHideMenuAvailable ? screenSize.width > 1180 : true);
  const idleTimerRef = useRef(null);
  const [showModalTimer, setShowModalTimer] = useState<boolean>(false);
  const [selectedImage, selectImage] = useState<File>();
  const [avatarViewControl, editAvatarView] = useState<NavImageView>({
    showAvatarModal: false,
    actualPicture: defaultImageUri,
    hasImage: false,
  });
  const [user, setUser] = useState<SafeUserDto>({
    email: '',
    id: '',
    name: '',
    organizationId: '',
    profilePicture: null,
    sdrId: null,
    timezone: '' as Timezone,
    userType: [],
  });
  const [sessionDetails, setSessionDetails] = useState<SessionDetail>({
    sessionId: 'unassigned',
    sdrName: 'unassigned',
    sdrId: 'unassigned',
    organizationId: 'unassigned',
    organizationName: 'unassigned',
  });

  const { timeAgo, updateTimeAgoDate } = useTimeAgo();

  const {endSession} = useAuth();

  const reload = async (): Promise<void> => {
    let tempSessionDetail: SessionDetail = {
      sessionId: 'unassigned',
      sdrName: 'unassigned',
      sdrId: 'unassigned',
      organizationId: 'unassigned',
      organizationName: 'unassigned',
    };

    const { data, success} = await getAgentValidations(
      currentUser.userId,
    );

    if(success && data !== undefined && data !== null){

      const session = data.callSession?.data;

      if(session && session.id){
        tempSessionDetail.sessionId = session.id;
      }

      const sdr = data.sdr?.data;

      if(sdr && sdr.isSdrAssignedToAgent){

        const response = await getUserAsync(sdr.id);

        const sdrName: string | undefined = response.data?.name;

        if(sdrName){
          tempSessionDetail.sdrName = sdrName;
        }

        if(sdr.organizationId){

          const { organization } = await getOrganizationInfo(sdr.organizationId)

          if(organization){
            tempSessionDetail.organizationId = organization.id;
            tempSessionDetail.organizationName = organization.name;
          }

        }

        tempSessionDetail.sdrId = sdr.id;

      }
      setSessionDetails(tempSessionDetail);
    }
    updateTimeAgoDate(new Date());
};

  const handleReload = (): void => {
    void reload();
  };

  const logout = () => {
    
    if (handleLogout) {
      
      handleLogout();
      clearCurrentUser();
      resetAllFeatures();
      endSession();
    }
  };

  const selectUserImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];

      const imageUrl = URL.createObjectURL(file);
      selectImage(file);
      editAvatarView({ ...avatarViewControl, showAvatarModal: true, actualPicture: imageUrl });
    }
  };

  async function uploadPicture() {
    if (selectedImage !== undefined) {
      const upload = await uploadProfilePicture(currentUser.userId, { file: selectedImage });

      const { data } = await getUserAsync(currentUser.userId);

      editAvatarView({ ...avatarViewControl, hasImage: true, showAvatarModal: false });

      if (upload.success !== false && data !== undefined) {
        setUser(data);
        editAvatarView({
          showAvatarModal: false,
          actualPicture: user.profilePicture as string,
          hasImage: user.profilePicture?.includes(defaultImageUri) as boolean,
        });

        showUIToast({
          type: UI_TOAST_TYPES.INFO,
          text: 'Your profile picture was successfully uploaded',
        });
        window.location.reload();
      } else {
        showUIToast({
          type: UI_TOAST_TYPES.ERROR,
          text: 'Your picture could not be uploaded',
        });
      }
    }
  }

  async function removePicture() {
    void getUserAsync(currentUser.userId).then((userData) => {
      if (userData.data !== undefined) {
        const { id, email, name, organizationId, userType, sdrId, timezone } = userData.data;

        void updateUserData({
          email: email,
          id: id,
          name: name,
          organizationId: organizationId,
          profilePicture: defaultImageUri,
          sdrId: sdrId,
          timezone: timezone,
          userType: userType,
        }).then(() => {
          window.location.reload();
          showUIToast({
            type: UI_TOAST_TYPES.INFO,
            text: 'Your profile picture was successfully removed',
          });
        });
      }
    });
  }

  useEffect(() => {
    let timerId;
    if (isAuthenticated) {
      timerId = setInterval(async function () {
        const token = window.localStorage.getItem('target') || '';

        if (token === '') {
          logout();
        }
        const decoded: decoded = jwt_decode(token);

        if (Date.now() >= decoded.exp * 1000 - MINUTE_TO_EXPIRATION && Date.now() <= decoded.exp * 1000) {
          await refreshSessionToken();
        } else if (Date.now() > decoded.exp * 1000) {
          logout();
        }
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    void getUserAsync(currentUser.userId).then((response) => {
      if (response.data !== undefined) {
        editAvatarView({
          ...avatarViewControl,
          actualPicture: response.data.profilePicture as string,
          hasImage: !(response.data.profilePicture as string).includes(defaultImageUri),
        });
      }
    });
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      await getUserInfoAsync(Layout['user']);
    };
    if (intervals?.layoutUserInfo) {
      clearInterval(intervals['layoutUserInfo']);
    }
    const newInterval = intervals || {};
    newInterval['layoutUserInfo'] = setInterval(() => {
      fetchUserInfo;
    }, 300000);
    setIntervals(newInterval);
  }, []);

  useEffect(() => {
    isAutoHideMenuAvailable && setMenuStatus(screenSize.width > 1180);
  }, [screenSize.width, isAutoHideMenuAvailable]);

  if (isAgent) {
    useEffect(() => {
      handleReload();
    }, []);
  }

  return (
    <UIMain className={`${menuStatus ? 'open' : 'closed'} ${sidebar && 'sidebar'}`}>
      <IdleTimerContainer pageRef={idleTimerRef} timedOutHandler={setShowModalTimer} />
      {showModalTimer && (
        <ExpiredSessionModal logoutAction={logout} modalTimer={showModalTimer} setModalTimer={setShowModalTimer} />
      )}
      <UIHeader>
        {sidebar && (
          <UIBurgerButton onClick={() => setMenuStatus(!menuStatus)}>
            {menuStatus ? <FA icon={['fas', 'bars']} /> : <FA icon={['fas', 'xmark']} />}
          </UIBurgerButton>
        )}

        <Label htmlFor="navAvatarInput" className="w-12 h-12">
          <NavAvatarImage
            src={avatarViewControl.actualPicture || defaultImageUri}
            twClass="object-fill rounded-full w-12 h-12 relative padding-auto"
          />
          {avatarViewControl.hasImage && isAgent && (
            <Button
              className="relative text-[10px] w-full h-1/4 top-2 mb-3 bg-destructive-foreground hover:bg-destructive hover:text-destructive-foreground bg-opacity-80"
              onClick={removePicture}
            >
              Remove
            </Button>
          )}
        </Label>

        {isAgent && (
          <div className='absolute'>
            <Input
              onChange={(e) => {
                selectUserImage(e);
              }}
              id="navAvatarInput"
              className="hidden"
              type={'file'}
              accept={'.jpeg, .jpg, .png, .svg, .bmp, .tiff'}
              data-qa-id={'chooseProfilePicNav'}
            />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                      <Button className="bg-transparent hover:bg-transparent text-primary rounded-full relative px-0 border-transparent w-4 h-4 bottom-[22px] left-[40px]"
                      >
                        <InfoCircledIcon/>
                      </Button>
                </TooltipTrigger>
                <TooltipContent className='bg-secondary text-primary'>
                  <p>Click your avatar to change picture</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <AlertDialog open={avatarViewControl.showAvatarModal}>
              <AlertDialogContent>
                <AlertDialogHeader className={'flex items-center'}>
                  <AlertDialogTitle>Do you want to upload this picture?</AlertDialogTitle>
                  <AlertDialogDescription>
                  <UserAvatarImage
                      twClass="object-fill w-32 h-32 relative padding-auto border border-secondary"
                      src={avatarViewControl.actualPicture}
                    />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className={'flex items-center'}>
                  <AlertDialogAction onClick={uploadPicture} data-qa-id={'changeProfilePicNav'}>
                    Change picture
                  </AlertDialogAction>
                  <AlertDialogCancel
                    className="border-none bg-destructive text-white hover:bg-destructive hover:text-white"
                    onClick={() => editAvatarView({ ...avatarViewControl, showAvatarModal: false })}
                    data-qa-id={'removeProfilePicNav'}
                  >
                    Cancel
                  </AlertDialogCancel>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}


<div className="flex justify-between items-center w-full">
    <div>
        <span>Welcome,</span> {currentUser.userName}
    </div>
    <div className="flex items-center">
    { isAgent &&
        <div className="flex justify-space-between w-full">
       
        <Button
          onClick={handleReload}
          className="flex items-center space-x-2 bg-transparent border-none text-blue-500 hover:text-blue-700 mt-7 mr-8"
        >
          {timeAgo !== undefined && <span>{timeAgo}</span>}
          <RefreshCwIcon className="h-5 w-5" />
        </Button>
    
       
        <div className="p-1 rounded mr-8 text-xs">
            <div className="font-bold mb-1 text-lg">
                Session Detail
            </div>
            <div>
                <strong>SDR:</strong> { sessionDetails.sdrName + " - " + sessionDetails.sdrId}
            </div>
            <div>
                <strong>Organization:</strong> {sessionDetails.organizationName + " - " + sessionDetails.organizationId}
            </div>
            <div>
                <strong>Session:</strong> {sessionDetails.sessionId}
            </div>
        </div>
        </div>
    }
        <UIButton data-qa-id="button-logout" onClick={logout} style={{ marginLeft: 'auto' }}>
          <FA icon={['fas', 'power-off']} />
          Logout
        </UIButton>
    </div>
</div>

      </UIHeader>
      {sidebar && (
        <SideBar
          intervals={intervals}
          setIntervals={setIntervals as (value: any) => void}
          callStatus={callStatus}
          sdrStatus={sdrStatus}
        />
      )}

      <UIContainer isSettings={isSettings} tabsIsEmpty={tabsIsEmpty}>
        {children}
      </UIContainer>
    </UIMain>
  );
};

export default Layout;
