import React, { type FC, useState } from 'react';
import { Input } from '@components/ui/input';
import { Button } from '@components/ui/button';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import { Label } from '@components/ui/label';
import { ChangePasswordViewModel } from '@views/Settings/Account/Components/ChangePassword.viewmodel';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/ui/tooltip';
import { toast } from '@components/ui/use-toast';

export const ChangePasswordComponent: FC = () => {
  const [currentShownPassword, currentShowPassword] = useState(false);
  const [shownPassword, showPassword] = useState(false);
  const [confirmShownPassword, confirmShowPassword] = useState(false);
  const {
    handleNewPassword,
    handleMatchConfirmPassword,
    saveNewPassword,
    handleInputCurrentPassword,
    newPassword,
    confirmPassword,
    currentPasswordInserted,
    passwordMatchMessage,
    feedback,
    passwordMatch,
    isStrongPassword,
  } = ChangePasswordViewModel();

  function toggleCurrentShowPassword(): void {
    if (currentShownPassword) {
      currentShowPassword(false);
    }
    if (!currentShownPassword) {
      currentShowPassword(true);
    }
  }
  function toggleShowPassword(): void {
    if (shownPassword) {
      showPassword(false);
    }
    if (!shownPassword) {
      showPassword(true);
    }
  }
  function toggleConfirmShowPassword(): void {
    if (confirmShownPassword) {
      confirmShowPassword(false);
    }
    if (!confirmShownPassword) {
      confirmShowPassword(true);
    }
  }

  function handleBlockPaste(event: React.ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    toast({
      title: 'Warning!',
      description: 'Pasting is not allowed in this field',
      variant: 'default',
    });
  }

  return (
    <div className="flex flex-col mt-3 gap-2 min-[1100px]:w-[40%] ">
      <div>
        <Label>Current password</Label>
        <div className={'flex relative mt-3 place-content-end'}>
          <Input
            id="currentPasswordInput"
            type={!currentShownPassword ? 'password' : 'text'}
            className="w-full pl-4"
            data-qa-id="currentPasswordInput"
            onChange={(e) => {
              handleInputCurrentPassword(e);
            }}
          />
          <Button
            className="border-none hover:bg-transparent bg-transparent absolute "
            onClick={toggleCurrentShowPassword}
          >
            {!currentShownPassword ? <IconEye color={'black'} /> : <IconEyeClosed color={'black'} />}
          </Button>
        </div>
      </div>

      <TooltipProvider>
        <Tooltip open={!isStrongPassword}>
          <TooltipTrigger asChild>
            <div>
              <Label>New password</Label>
              <div className={'flex relative mt-3 place-content-end'}>
                <>
                  <Input
                    id="newPasswordInput"
                    type={!shownPassword ? 'password' : 'text'}
                    className={'w-full pl-4'}
                    data-qa-id="newPasswordInput"
                    onChange={(e) => {
                      handleNewPassword(e);
                    }}
                  />
                  <Button
                    className="border-none hover:bg-transparent bg-transparent absolute "
                    onClick={toggleShowPassword}
                  >
                    {!shownPassword ? <IconEye color={'black'} /> : <IconEyeClosed color={'black'} />}
                  </Button>
                </>
              </div>
              <div className={'mt-1.5'}>
                <Label>{feedback}</Label>
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent className={'relative left-[300px] top-[72px] bg-transparent text-[#ff6666] font-bold'}>
            {newPassword.current !== undefined && <p> Password is not strong enough</p>}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div>
        <Label>Confirm new password</Label>
        <div className={'flex relative mt-3 place-content-end'}>
          <Input
            id="confirmNewPasswordInput"
            type={!confirmShownPassword ? 'password' : 'text'}
            className="w-full pl-4"
            data-qa-id="confirmNewPasswordInput"
            onPaste={(event) => {
              handleBlockPaste(event);
            }}
            onChange={(e) => {
              handleMatchConfirmPassword(e);
            }}
          />
          <Button
            className="border-none hover:bg-transparent bg-transparent absolute"
            onClick={toggleConfirmShowPassword}
          >
            {!confirmShownPassword ? <IconEye color={'black'} /> : <IconEyeClosed color={'black'} />}
          </Button>
        </div>
        <div className={'mt-1.5'}>
          <Label>{passwordMatchMessage !== '' ? passwordMatchMessage : ''}</Label>
        </div>
        {currentPasswordInserted && (
          <Button
            className="bg-app-blue-light mt-3"
            variant="default"
            type="submit"
            formTarget="avatarInput"
            data-qa-id="changePasswordButton"
            disabled={!passwordMatch || !isStrongPassword || confirmPassword === undefined}
            onClick={saveNewPassword}
          >
            Change password
          </Button>
        )}
      </div>
    </div>
  );
};
