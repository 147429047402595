import React from 'react';
import { IconRotateClockwise } from '@tabler/icons-react';

interface LoaderProps {
  message: string;
  size?: string;
}
const UILoader: React.FC<LoaderProps> = ({ message, size }) => {
  return (
    <div
      className={`${
        size != null ? `w-${size} h-${size}` : 'w-full h-full'
      } absolute z-5 flex flex-col content-center items-center justify-center text-center gap-[1em] bg-white backdrop-blur text-app-gray-500 font-bold`}
    >
      <IconRotateClockwise stroke={1} color="hsl(var(--primary))" className="animate-loader-spin" size={80} />
      <div className="font-extrabold">{message}</div>
    </div>
  );
};

export default UILoader;
