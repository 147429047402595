import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';

async function getSessionReportAsync(callSessionId: string, type: string): Promise<ApiResponse<string>> {
  try {
    const generatedReport: ApiResponse<string> = await axios.get(
      `${routes.get.callSession.getReportNest}/${callSessionId}`,
      {
        params: { type },
      },
    );
    return { data: generatedReport.data, success: true };
  } catch {
    return { error: { message: 'Could not get session report' }, success: false };
  }
}

export default getSessionReportAsync;
