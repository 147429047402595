import { type CurrentUser } from '@/src/types';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import LayoutSessionTokensUseCase from '@usecase/layout/layoutSessionTokensUseCase';
import { type Timezone } from '@domain/enums/Users/timezone';
import axios from 'axios';
import { PublicRoutes } from '@/src/constants/routesConnect';
import { toast } from '@components/ui/use-toast';
import { useState } from 'react';
import { type SafeUserDto } from '@datasource/edge/users/dto/safeUser.dto';
import { useCookies } from 'react-cookie';

interface LayoutViewModelProps {
  logoutUser: () => void;
  currentUser: CurrentUser;
  isAgent: boolean;
  isSdr: boolean;
  user: SafeUserDto;
}
export function LayoutViewModel(): LayoutViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  const [cookie, setCookie] = useCookies(['access_token', 'refresh_token']);
  const [user, setUser] = useState<SafeUserDto>({
    email: '',
    id: '',
    name: '',
    organizationId: '',
    profilePicture: null,
    sdrId: null,
    timezone: '' as Timezone,
    userType: [],
  });
  const {
    userSession: currentUser,
    userTypeAgent: isAgent,
    userTypeSdr: isSdr,
  } = useOutletContext<OutletContextProps>();

  const navigate = useNavigate();
  const { edgeLogout, authLogout } = LayoutSessionTokensUseCase();

  const invalidateSession = (): void => {
    window.localStorage.removeItem('target');
    window.localStorage.removeItem('user');
    setUser({
      email: '',
      id: '',
      name: '',
      organizationId: '',
      profilePicture: null,
      sdrId: null,
      timezone: '' as Timezone,
      userType: [],
    });
    delete axios.defaults.headers.common.Authorization;
    if (cookie.refresh_token !== '' || cookie.access_token !== '') {
      setCookie('access_token', '', { path: '/', expires: new Date() });
      setCookie('refresh_token', '', { path: '/', expires: new Date() });
    }
  };

  function logoutUser(): void {
    const token = window.localStorage.getItem('target');
    if (token !== null) {
      navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
      void authLogout();
      void edgeLogout(token);
      toast({
        title: 'Success!',
        description: 'User logged out successfully',
        variant: 'default',
      });
      invalidateSession();
    }
  }

  return {
    logoutUser,
    currentUser,
    isSdr,
    isAgent,
    user,
  };
}
