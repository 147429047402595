import routes from "../../constants/routes";
import axios from "axios";
import { AgentValidationsDto } from "../../types/AgentValidationsTypes";
import { ApiResponse } from "../../types/ApiResponse";

const getAgentValidations = async (
  agentId: string
): Promise<ApiResponse<AgentValidationsDto>> => {
  try {
    const url = `${routes.get.users.getAgentValidationsNest}/${agentId}`;
    const validations = await axios.get<AgentValidationsDto>(url);
    return {
      success: true,
      data: validations.data,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (axios.isAxiosError(e) && e?.response?.status === 500) {
      console.log(e)
    }
    return {
      success: false,
      error: {
        message:
          "An error occurred while retrieving agent validations. If the issue persists, please contact customer support.",
      },
    };
  }
};
export default getAgentValidations;
