import { type ApiResponse } from '@/src/types/ApiResponse';
import routes from '@/src/constants/routes';
import axios from 'axios';

export async function logoutEdgeAsync(token: string): Promise<ApiResponse<string>> {
  try {
    const logoutResponse: ApiResponse<string> = await axios.post(routes.post.auth.logout, token);
    return { data: logoutResponse.data, success: true };
  } catch {
    return { error: { message: 'Error while logging out' }, success: false };
  }
}
