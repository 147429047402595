import { type ResetPasswordAdminDto } from '@datasource/auth/password/dto/resetPasswordDto';
import type { ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';

export async function resetPasswordAdminAsync(
  body: ResetPasswordAdminDto,
): Promise<ApiResponse<Record<string, string>>> {
  try {
    const postConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const updatedPass = await axios.post(routes.post.auth.resetPasswordAdmin, body, postConfig);
    return { success: true, data: updatedPass.data as Record<string, string> };
  } catch {
    return { error: { message: 'Cannot reset password' } };
  }
}
