import ActiveSessionsUseCase from '@usecase/active-sessions/activeSessionsUseCase';
import {
  ActiveCallSession,
  type CurrentSdrData,
  type AgentCallInfo,
  type UpdateStatus,
} from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { useEffect, useState } from 'react';
import { showUIToast, UI_TOAST_TYPES } from '@/src/core/ui/UIToast';

interface ActiveSessionsViewModelProps {
  fetchSessionInfo: () => Promise<ActiveCallSession[] | undefined>;
  getAgentsCallInfo: (sessionId: string) => Promise<AgentCallInfo[] | undefined>;
  sessionsData: ActiveCallSession[] | undefined;
  selectedSdrData: CurrentSdrData;
  agentsCallInfo: AgentCallInfo[] | undefined;
  toggleResumePauseSession: (callSessionId: string, data: UpdateStatus) => Promise<void>;
  removeAgentsBySessionId: (CallSessionId: string) => Promise<void>;
}

function ActiveSessionsViewmodel(): ActiveSessionsViewModelProps {
  const [sessionsData, setSessionsData] = useState<ActiveCallSession[] | undefined>([]);
  const [selectedSdrData, setSelectedSdrData] = useState<CurrentSdrData>({
    sdrId: '',
    name: '',
    orgId: '',
    orgName: '',
  });
  const [agentsCallInfo, setAgentsCallInfo] = useState<AgentCallInfo[] | undefined>([]);
  const { getActiveSessionsInfo, getCallSession, updateSessionStatus, fetchAgentsCallInfo, deleteAgentsBySessionId } =
    ActiveSessionsUseCase();

  async function fetchSessionInfo(): Promise<ActiveCallSession[] | undefined> {
    const { data } = await getActiveSessionsInfo();
    try {
      const sessionOverview = data?.map((session) => {
        return new ActiveCallSession(
          session.callSessionId,
          session.sdrId,
          session.status,
          session.sdrStatus,
          session.startAt,
          session.sdrName,
          session.organization,
          session.organizationId,
          session.agents,
        );
      });

      if (data?.length === 0) {
        showUIToast({
          type: UI_TOAST_TYPES.INFO,
          text: `There are not current active sessions`,
        });
      }
      setSessionsData(sessionOverview);
      return sessionOverview;
    } catch (err) {
      console.error(err);
    }
  }
  async function getAgentsCallInfo(sessionId: string): Promise<AgentCallInfo[] | undefined> {
    const { success, error, data } = await fetchAgentsCallInfo(sessionId);

    if (success === true && data !== undefined && data.length > 0) {
      setAgentsCallInfo(data);

      const sdrData = data[0];

      setSelectedSdrData({
        sdrId: sdrData.sdrId,
        name: sdrData.sdrName,
        orgId: sdrData.orgId,
        orgName: sdrData.orgName,
      });
    } else if (error !== null && error !== undefined) {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: error.message,
      });
    } else {
      showUIToast({
        type: UI_TOAST_TYPES.INFO,
        text: 'No call logs were found for this session.',
      });
    }
    return data;
  }

  async function updateSessionStatuses(callSessionId: string, body: UpdateStatus): Promise<void> {
    const { data } = await getCallSession(callSessionId);

    if (data !== undefined) {
      data.status = body.status !== undefined ? body.status : data.status;

      data.sdrStatus = body.sdrStatus !== undefined ? body.sdrStatus : data.sdrStatus;

      await updateSessionStatus(callSessionId, data);
      showUIToast({
        type: UI_TOAST_TYPES.INFO,
        text: `Call Session updated`,
      });
      await fetchSessionInfo();
    } else {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error on updating Call Session',
      });
    }
  }

  async function removeAgentsBySessionId(callSessionId: string): Promise<void> {
    const { data } = await deleteAgentsBySessionId(callSessionId);

    if (data !== undefined) {
      showUIToast({
        type: UI_TOAST_TYPES.INFO,
        text: `Agents successfully removed`,
      });
      await fetchSessionInfo();
    } else {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error when trying to remove agents.',
      });
    }
  }

  useEffect(() => {
    if (sessionsData === undefined || sessionsData.length === 0) {
      void fetchSessionInfo();
    }
  }, [setSessionsData]);

  return {
    fetchSessionInfo,
    getAgentsCallInfo,
    sessionsData,
    selectedSdrData,
    agentsCallInfo,
    toggleResumePauseSession: updateSessionStatuses,
    removeAgentsBySessionId,
  };
}

export default ActiveSessionsViewmodel;
