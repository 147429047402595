import axios from 'axios';
import routes from '../../../../../src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type LoginDto, type AuthenticationResponse } from '@datasource/auth/login/dto/login.dto';

export async function loginAuth(credentials: LoginDto): Promise<ApiResponse<AuthenticationResponse>> {
  try {
    const loginConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response: ApiResponse<AuthenticationResponse> = await axios.post(
      routes.post.auth.authLogin,
      credentials,
      loginConfig,
    );
    return {
      success: true,
      data: response.data,
    };
  } catch {
    return {
      success: false,
      error: {
        message: 'An error occurred while login in. If the problem persists, please reach tech support',
      },
    };
  }
}
