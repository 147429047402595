import { type CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';

async function deletAgentsFromSessionBySessionId(callSessionId: string): Promise<ApiResponse<CallSession>> {
  try {
    const callSession = await axios.delete<CallSession>(
      `${routes.delete.callSession.agentsBySessionId}${callSessionId}`,
    );
    return { data: callSession.data };
  } catch {
    return { error: { message: 'An error occurred when deleting agents from the session.' }, success: false };
  }
}

export default deletAgentsFromSessionBySessionId;
