import React, { type FC, useRef } from 'react';
import { IconPower } from '@tabler/icons-react';
import { Label } from '@components/ui/label';
import { NavAvatarImage, UserAvatarImage } from '@views/Settings/Account/Components/UserAvatarImage.component';
import { Button } from '@components/ui/button';
import { Input } from '@components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/ui/tooltip';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from '@components/ui/alert-dialog';
import { AlertDialogDescription, AlertDialogTitle } from '@radix-ui/react-alert-dialog';
import { NavBarViewmodel } from '@views/Layout/Components/NavBar.viewmodel';
import { LayoutViewModel } from '@views/Layout/Layout.viewmodel';

interface NavBarProps {
  children: React.ReactNode;
}

const NavbarComponent: FC<NavBarProps> = ({ children }) => {
  const { selectUserImage, removePicture, uploadPicture, defaultImageUri, avatarViewControl, currentUser } =
    NavBarViewmodel();
  const { isAgent, logoutUser } = LayoutViewModel();
  const viewControl = useRef(avatarViewControl);
  return (
    <div
      id="navBar"
      className="z-1 grid grid-cols-[93%_auto] transition duration-500 content-center px-9 min-w-[900px] max-[1400px]:max-w-[90%]"
    >
      <div className="flex gap-4 items-center">
        {children}
        <Label htmlFor="navAvatarInput" className="w-12 h-12 cursor-pointer">
          <NavAvatarImage
            src={viewControl.current.actualPicture !== '' ? viewControl.current.actualPicture : defaultImageUri}
            twClass="object-fill rounded-full w-12 h-12 relative padding-auto"
          />
          {avatarViewControl.hasImage && isAgent && (
            <Button
              className="relative text-[10px] w-full h-1/4 top-2 mb-3 bg-destructive-foreground hover:bg-destructive hover:text-destructive-foreground bg-opacity-80"
              onClick={removePicture}
            >
              Remove
            </Button>
          )}
        </Label>
        {isAgent && (
          <div className="absolute">
            <Input
              onChange={(e) => {
                selectUserImage(e);
              }}
              id="navAvatarInput"
              className="hidden"
              type={'file'}
              accept={'.jpeg, .jpg, .png, .svg, .bmp, .tiff'}
              data-qa-id={'chooseProfilePicNav'}
            />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button className="bg-transparent hover:bg-transparent text-primary rounded-full relative px-0 border-transparent w-4 h-4 bottom-[22px] left-[40px]">
                    <InfoCircledIcon />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-secondary text-primary">
                  <p>Click your avatar to change picture</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <AlertDialog open={avatarViewControl.showAvatarModal}>
              <AlertDialogContent>
                <AlertDialogHeader className={'flex items-center'}>
                  <AlertDialogTitle>Do you want to upload this picture?</AlertDialogTitle>
                  <AlertDialogDescription>
                    <UserAvatarImage
                      twClass="object-fill w-32 h-32 relative padding-auto border border-secondary"
                      src={avatarViewControl.actualPicture}
                    />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className={'flex items-center'}>
                  <AlertDialogAction onClick={uploadPicture} data-qa-id={'changeProfilePicNav'}>
                    Change picture
                  </AlertDialogAction>
                  <AlertDialogCancel
                    className="border-none bg-destructive text-white hover:bg-destructive hover:text-white"
                    onClick={() => {
                      viewControl.current = { ...avatarViewControl, showAvatarModal: false };
                    }}
                    data-qa-id={'removeProfilePicNav'}
                  >
                    Cancel
                  </AlertDialogCancel>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}
        <div className="text-lg">
          <span className="text-xl font-semibold">Welcome</span> {currentUser.userName}
        </div>
      </div>
      <div className="flex gap-3 px-3 items-center justify-center rounded-md text-white text-lg bg-primary min-w-[100px] w-[125px] cursor-pointer">
        <IconPower color="#FFFFFF" />
        <button data-qa-id={'logoutBtn'} onClick={logoutUser}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default NavbarComponent;
